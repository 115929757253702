import {ChangeDetectionStrategy, Component, input, InputSignal, output} from '@angular/core';
import {QuestionSlide} from "@models/slide.model";
import {QuestionProgress} from "@models/course-progress-data-types.model";
import {QuestionSectionComponent} from "./question-section/question-section.component";
import {AnswerSectionComponent} from "./answer-section/answer-section.component";
import {CorrectAnswerTooltipComponent} from "./correct-answer-tooltip/correct-answer-tooltip.component";
import {HighlightAnswerDirective} from "./highlight-answer.directive";
import {AnswerPromptComponent} from "./answer-prompt/answer-prompt.component";
import {AsyncPipe} from "@angular/common";
import {Observable} from "rxjs";
import {CourseFacadeService} from "@core/facades/course.facade.service";
import {IncorrectAnswerTooltipComponent} from "./incorrect-answer-tooltip/incorrect-answer-tooltip.component";
import {LayoutTemplateComponent} from "../layout-slide/layout-template/layout-template.component";

@Component({
  selector: 'sg-question-slide',
  standalone: true,
  imports: [
    QuestionSectionComponent,
    AnswerSectionComponent,
    CorrectAnswerTooltipComponent,
    HighlightAnswerDirective,
    AnswerPromptComponent,
    AsyncPipe,
    IncorrectAnswerTooltipComponent,
    LayoutTemplateComponent,
  ],
  templateUrl: './question-slide.component.html',
  styleUrl: './question-slide.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class QuestionSlideComponent {

  questionSlide: InputSignal<QuestionSlide> = input.required();
  questionProgress: InputSignal<QuestionProgress> = input.required();

  saveProgress = output<QuestionProgress>();
  isSlideUnlocked = false;
  selectedLanguage$: Observable<string>;

  constructor(private _courseFacade: CourseFacadeService) {
    this.selectedLanguage$ = _courseFacade.selectedLanguage$
  }


  /**
   *Select Answer
   * @param index
   * @param selectAllCorrectAnswers
   */
  selectAnswer(index: number, selectAllCorrectAnswers: boolean) {
    if (!(this.questionProgress().selectedAnswerIndexes.length > 0 && this.questionProgress().isSlideUnlocked)) {
      this.questionProgress().selectedAnswerIndexes.push(index);

      if (selectAllCorrectAnswers) {
        this.questionProgress().isSlideUnlocked = this.questionSlide().correctAnswersIndex.every(index => (
          this.questionProgress().selectedAnswerIndexes.includes(index))
        );
      } else {
        this.questionProgress().isSlideUnlocked = this.questionSlide().correctAnswersIndex.some(index => (
          this.questionProgress().selectedAnswerIndexes.includes(index))
        );
      }

      this.questionProgress().slideCompleted = this.questionProgress().isSlideUnlocked;
      this.questionProgress().isSelectedAnswerCorrect = this.questionSlide().correctAnswersIndex.some(i => i === index);
      this.saveProgress.emit(this.questionProgress());
    }

  }

}
