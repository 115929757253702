import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { Course } from '@models/course.model';
import { CourseFacadeService } from '@core/facades/course.facade.service';
import { SlideDataUnit } from '@models/course-progress-data-types.model';
import { Store } from '@ngrx/store';
import { canGoBack } from '@root-store/course/course.selectors';

@Injectable()
export class LayoutFacadeService {

  course$: Observable<Course>;
  slideDataUnit$: Observable<SlideDataUnit>;
  canGoBack$: Observable<boolean>;


  constructor(private _courseFacade: CourseFacadeService,
              private _store: Store) {

    this.course$ = _courseFacade.course$;
    this.slideDataUnit$ = _courseFacade.slideDataUnit$;
    this.canGoBack$ = _store.select(canGoBack);
  }

}
