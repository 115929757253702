import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import { NgIf } from '@angular/common';
import { MuteIconComponent } from './mute-icon/mute-icon.component';
import { LowVolumeIconComponent } from './low-volume-icon/low-volume-icon.component';
import { MediumVolumeIconComponent } from './medium-volume-icon/medium-volume-icon.component';
import { HighVolumeIconComponent } from './high-volume-icon/high-volume-icon.component';

@Component({
    selector: 'sc-volume-icon',
    templateUrl: './volume-icon.component.html',
    styleUrls: ['./volume-icon.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [NgIf, MuteIconComponent, LowVolumeIconComponent, MediumVolumeIconComponent, HighVolumeIconComponent]
})
export class VolumeIconComponent implements OnInit {

  @Input() volume: number;

  constructor() { }

  ngOnInit(): void {
  }

}
