import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { AnimationOptions, LottieComponent } from 'ngx-lottie';

@Component({
  selector: 'sg-animation',
  templateUrl: './animation.component.html',
  standalone: true,
  styleUrls: ['./animation.component.scss'],
  imports: [
    LottieComponent
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AnimationComponent {

  @Input() set url(url: string) {
    this.options = {
      path: url
    };
  }

  options: AnimationOptions;

  constructor() {
  }

}
