<sg-layout-template [layoutType]="questionSlide().layoutType"
                    [graphic]="questionSlide().graphic">

  <div class="question-container">
    @if (questionSlide(); as questionSlide) {
      <div class="question-section-wrapper">
        <sg-question-section [question]="questionSlide.question"
                             [questionTip]="questionSlide.questionTip"/>
        @for (answer of questionSlide.answers; track answer; let index = $index) {
          <sg-answer-section [highlightAnswer]="index"
                             [answered]="questionProgress().selectedAnswerIndexes.includes(index)"
                             [index]="index"
                             [correctAnswerIndexes]="questionSlide.correctAnswersIndex"
                             [savedAnswerIndexes]="questionProgress().selectedAnswerIndexes"
                             [answer]="answer"
                             (click)="selectAnswer(index, questionSlide.selectAllCorrectAnswers)"/>
        }

        @if (this.questionProgress().selectedAnswerIndexes.length > 0 && !this.questionProgress().isSelectedAnswerCorrect) {
          <sg-incorrect-answer-tooltip>
            {{ questionSlide?.incorrectAnswerTooltip }}
          </sg-incorrect-answer-tooltip>
        }

        @if (questionSlide?.correctAnswerTooltip && this.questionProgress().isSelectedAnswerCorrect) {
          <sg-correct-answer-tooltip>
            {{ questionSlide.correctAnswerTooltip }}
          </sg-correct-answer-tooltip>
        }
      </div>
    }
  </div>
</sg-layout-template>



