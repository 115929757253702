import {Pipe, PipeTransform} from '@angular/core';
import {convertSecondsToMinuteString} from '@core/core.utils'

@Pipe({
    name: 'convertSecondsToMinuteString',
    standalone: true
})
export class ConvertSecondsToMinuteStringPipe implements PipeTransform {

  transform(value: number): unknown {
    return convertSecondsToMinuteString(value)
  }

}
