import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'sc-language-link-item',
    templateUrl: './language-link-item.component.html',
    styleUrls: ['./language-link-item.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true
})
export class LanguageLinkItemComponent implements OnInit {

  @Input() languageText: string | null;

  constructor() { }

  ngOnInit(): void {
  }

}
