import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {AnswerPromptPipe} from "../answer-prompt.pipe";

@Component({
  selector: 'sg-answer-prompt',
  templateUrl: './answer-prompt.component.html',
  standalone: true,
  styleUrls: ['./answer-prompt.component.scss'],
  imports: [
    AnswerPromptPipe
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AnswerPromptComponent {

  @Input() selectedLanguage: string | null;

}
