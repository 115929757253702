import {Injectable} from '@angular/core';
import {map} from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import {environment} from '@env/environment';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {

  constructor(private _http: HttpClient) {
  }

  /************************************************************************
   * Get all languages
   * > Observable<Language[]>
   */
  getLanguages(courseId?: string) {

    if (courseId) {
      return this._http.get(environment.apiBaseUrl + `courses/${courseId}/languages`).pipe(
        map((res: any) => res.data)
      )
    }
    return this._http.get(`./assets/json/languages.json`)
    //   .pipe(
    //   map((languages: any) => ({
    //       languages,
    //       config: {
    //         primaryColor: '#224D58'
    //       }
    //     })
    //   )
    // ) as Observable<{ languages: Language[], config: object }>
  }
}
