import { Directive, ElementRef, input, Input, InputSignal, Renderer2 } from '@angular/core';

@Directive({
  selector: '[highlightAnswer]',
  standalone:true,
})
export class HighlightAnswerDirective{

  answered = input.required({
    transform: (answered: boolean) => {
      if(answered) {
        this._renderer.addClass(this._hostElement.nativeElement, 'answered');
      } else {
        this._renderer.removeClass(this._hostElement.nativeElement, 'answered');
      }
      return answered;
    }
  });

  correctAnswerIndexes: InputSignal<number[]> = input.required()

  savedAnswerIndexes = input.required({
    transform: (savedAnswerIndexes: number[]) => {
      this._checkAnswer()
      return savedAnswerIndexes || [];
    }
  })

  @Input('highlightAnswer') index: number;
  // @Input() savedAnswerIndexes: number[];

  constructor(private _renderer: Renderer2,
              private _hostElement: ElementRef) {
  }

  private _checkAnswer() {

    if(this._isAnswerCorrect()) {
      this._renderer.addClass(this._hostElement.nativeElement, 'correct');
    } else if(this.answered()) {
      this._renderer.addClass(this._hostElement.nativeElement, 'wrong')
    }
  }

  private _isAnswerCorrect() {
    return this.correctAnswerIndexes().some(i => this.index === i)
  }

}
