import {Course} from '@models/course.model';
import {
  ProgressDataFactory, SlideProgressData,
} from '@models/course-progress-data-types.model';


/**+
 * course progress data
 * to save in scorm using scorm-api
 */
export class CourseProgress {
  overallProgress: number = 0;
  slideLength: number;
  isCompleted: boolean = false;
  slideData: SlideProgressData;
  cpi: number = 0; // Current pointer index
  asi: string; // Active Slide Id
  canGoBack: boolean = false;
  languageCode: string;
  totalTime: number = 0;
  completedTime: number = 0;

  constructor(course: Course,
              fullSlideIdList: string | (string | (string | string[])[])[],
              languageCode: string,
              asi: string,
              isUnlocked?: boolean
  ) {

    this.languageCode = languageCode
    this.slideLength = fullSlideIdList.length;
    this.asi = asi;

    this.slideData = ProgressDataFactory.createSlideProgressData(course.slides, !!isUnlocked);

    Object
      .values(this.slideData)
      .forEach(slide => {
        this.totalTime += slide.duration;
      })

  }
}

