import {
  ChangeDetectionStrategy,
  Component,
  inject,
  input,
  InputSignal,
  output,
  signal,
  WritableSignal
} from '@angular/core';
import { LayoutType, LevelSlide, TileSlide } from '@models/slide.model';
import { LottieComponent } from 'ngx-lottie';
import { TileSlideComponent } from '../tile-slide/tile-slide.component';
import { TileDecoratorDirective } from './tile-decorator.directive';
import { CourseFacadeService } from '@core/facades/course.facade.service';
import { GroupProgress, SlideDataUnit , SlideProgress} from '@models/course-progress-data-types.model';
import {DynamicDialogService} from '@shared/dynamic-dialogs/dynamic-dialog.service';

@Component({
  selector: 'sg-level-slide',
  standalone: true,
  imports: [
    LottieComponent,
    TileSlideComponent,
    TileDecoratorDirective,
  ],
  templateUrl: './level-slide.component.html',
  styleUrl: './level-slide.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LevelSlideComponent {

  levelSlide: InputSignal<LevelSlide> = input.required({
    transform: (levelSlide) => {
      this.contentDataUnits = this._courseFacade.selectGroupDataUnitList<TileSlide>(levelSlide.id) as SlideDataUnit<TileSlide>[];
      const tiles = Object.keys(levelSlide.content).map(key => ({...levelSlide.content[key]}));
      this.tiles.set(tiles);

      return levelSlide;
    }
  });

  levelProgress: InputSignal<GroupProgress> = input.required({
    transform: (levelProgress) => {
      // this.saveProgress.emit(levelProgress)
      return levelProgress
    }
  });


  tiles: WritableSignal<TileSlide[]> = signal([]);

  contentDataUnits: SlideDataUnit<TileSlide>[];
  saveProgress = output<GroupProgress>();

  private _courseFacade = inject(CourseFacadeService);
  private _dynamicDialogService = inject(DynamicDialogService);


  onSelectTile(tileProgress: SlideProgress, tile: TileSlide, index: number) {
    if ((tileProgress as GroupProgress).err) {

      this._dynamicDialogService.openInformationDialog({
        header: 'Tile Content Unavailable!',
        bodyText: "There's currently no content to display on this tile"
      })

      return;
    }

    const isLock = (this.contentDataUnits[index].slideProgress as GroupProgress).isLock

    if (isLock || !(tile.content && Object.keys(tile.content).length > 0)) {
      return;
    }
    this._courseFacade.selectSubGroup(tileProgress.id)
  }

  protected readonly LayoutType = LayoutType;
}
