import { ChangeDetectionStrategy, Component } from '@angular/core';
import { LayoutFacadeService } from '@core/layout/layout.facade.service';
import { Observable } from 'rxjs';
import { Course } from '@models/course.model';
import { NgIf, AsyncPipe } from '@angular/common';
import { PlayerControlsComponent } from './player-controls/player-controls.component';

@Component({
    selector: 'sc-layout',
    templateUrl: './layout.component.html',
    styleUrls: ['./layout.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [NgIf, PlayerControlsComponent, AsyncPipe]
})
export class LayoutComponent {

  course$: Observable<Course>;

  constructor(private _layoutFacade: LayoutFacadeService) {
    this.course$ = this._layoutFacade.course$
  }
}
