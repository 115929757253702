import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { NgStyle } from '@angular/common';

@Component({
    selector: 'sc-course-progress-bar',
    templateUrl: './course-progress-bar.component.html',
    styleUrls: ['./course-progress-bar.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [NgStyle]
})
export class CourseProgressBarComponent implements OnInit {

  @Input() set courseCompletionTimer(vals: { totalTime: number, completedTime: number }) {
    this.courseProgress =  vals ? (100 * vals.completedTime) / vals.totalTime : 0;
  }

  courseProgress: number = 0;

  constructor() { }

  ngOnInit(): void {
  }

}
