<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g clip-path="url(#clip0_1429_11080)">
    <path d="M17.1515 4.95166C16.6874 4.48694 15.9337 4.48723 15.469 4.95166L7.8968 12.5242L4.53129 9.15866C4.06656 8.69394 3.31326 8.69394 2.84854 9.15866C2.38382 9.62339 2.38382 10.3767 2.84854 10.8414L7.05526 15.0481C7.28747 15.2803 7.59196 15.3967 7.89648 15.3967C8.201 15.3967 8.50579 15.2806 8.738 15.0481L17.1515 6.63437C17.6162 6.16997 17.6162 5.41635 17.1515 4.95166Z" fill="white"/>
  </g>
  <defs>
    <clipPath id="clip0_1429_11080">
      <rect width="15" height="15" fill="white" transform="translate(2.5 2.5)"/>
    </clipPath>
  </defs>
</svg>
