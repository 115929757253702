import { Component,  input,  InputSignal } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import {CheckIconComponent} from "./check-icon/check-icon.component";

@Component({
  selector: 'sc-checkbox',
  standalone: true,
  templateUrl: './checkbox.component.html',
  styleUrl: './checkbox.component.scss',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: CheckboxComponent
    }
  ],
  imports: [
    CheckIconComponent
  ]
})
export class CheckboxComponent implements ControlValueAccessor {

  label: InputSignal<string> = input.required();

  checked: boolean
  onChange: (checked: boolean) => void;
  onTouched: () => void;

  toggle() {
    this.checked = !this.checked;
    this.onChange(this.checked);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  writeValue(checked: boolean): void {
    this.checked = checked;
  }
}
