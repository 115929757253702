import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';

@Component({
    selector: 'sc-back-icon',
    templateUrl: './back-icon.component.html',
    styleUrls: ['./back-icon.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true
})
export class BackIconComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
