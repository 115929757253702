import {Pipe, PipeTransform} from '@angular/core';
import {Translation} from "@models/course.model";
import translationData from "@assets/json/translation.json";

@Pipe({
  name: 'translation',
  standalone: true
})
export class TranslationPipe implements PipeTransform {

  translations: { [key: string]: Translation }

  constructor() {
    this.translations = translationData;
  }

  transform(name: string, languageCode: string | null): any {
    languageCode = languageCode ? languageCode : 'en';
    let translation = this.translations[languageCode]
    type objectKey = keyof typeof translation;
    const type = name as objectKey;
    return this.translations[languageCode] ? this.translations[languageCode][type] : this.translations['en'][type];
  }

}
