@if (isCompleted()) {
  <svg width="48" height="48" viewBox="0 0 48 48" fill="none"
       xmlns="http://www.w3.org/2000/svg">
    <circle id="selection" cx="24" cy="24" r="5" [style.fill]="'#22AD1F'" [style.stroke]="'#22AD1F'"
            stroke-width="4"/>
    <circle id="circle_1" opacity="0.75" cx="24" cy="24" r="15" [style.stroke]="'#22AD1F'" stroke-width="2"/>
    <circle id="circle_2" opacity="0.3" cx="24" cy="24" r="19" [style.stroke]="'#22AD1F'" stroke-width="2"/>
    <circle id="circle_3" opacity="0.1" cx="24" cy="24" r="23" [style.stroke]="'#22AD1F'" stroke-width="2"/>
  </svg>
} @else {
  <svg width="48" height="48" viewBox="0 0 48 48" fill="none"
       xmlns="http://www.w3.org/2000/svg">
    <circle id="selection" cx="24" cy="24" r="5" [style.fill]="pointer().pointerColor"
            [style.stroke]="pointer().pointerColor" stroke-width="4"/>
    <circle id="circle_1" opacity="0.75" cx="24" cy="24" r="15" [style.stroke]="pointer().pointerColor"
            stroke-width="2"/>
    <circle id="circle_2" opacity="0.3" cx="24" cy="24" r="19" [style.stroke]="pointer().pointerColor"
            stroke-width="2"/>
    <circle id="circle_3" opacity="0.1" cx="24" cy="24" r="23" [style.stroke]="pointer().pointerColor"
            stroke-width="2"/>
  </svg>
}
