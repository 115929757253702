import {createFeatureSelector, createSelector} from '@ngrx/store';

import {courseFeatureKey} from './course.reducer';
import {CourseState} from '@models/course-state.model';
import {
  ProgressDataFactory,
} from '@models/course-progress-data-types.model';

const selectCourseState = createFeatureSelector<CourseState>(courseFeatureKey);

/************************************************************************
 * check if course is loaded
 * > boolean
 */
export const isCourseLoaded = createSelector(
  selectCourseState,
  (courseState) => {
    return courseState.isCourseLoaded
  }
);

/************************************************************************
 * check if course is loading
 * > boolean
 */
export const isCourseLoading = createSelector(
  selectCourseState,
  (courseState) => courseState.isCourseLoading
);

/************************************************************************
 * Select Slide Ids As an Array
 * > string[]
 */
export const selectCourse = createSelector(
  selectCourseState,
  (courseState) => courseState?.course
);

/************************************************************************
 * Select Active Slide Id
 * > Slide
 */
export const selectCourseProgress = createSelector(
  selectCourseState,
  ({courseProgress}) => courseProgress
);

/************************************************************************
 * Select Player primary color
 * > string
 */
export const selectPlayerColor = createSelector(
  selectCourseState,
  (courseState) => {
    return courseState.course?.config?.primaryColor
  }
);

/************************************************************************
 * Select Active Slide
 * > Slide
 */
export const selectActiveSlideData = createSelector(
  selectCourseState,
  selectCourseProgress,
  ({course}, {asi, slideData}) => {

    if(course) {
      return ProgressDataFactory.createSlideDataUnit(asi, course.slides, slideData)
    }

    return null;

  }
);
/************************************************************************
 * Course progress
 * > number
 */
export const selectCourseOverallProgress = createSelector(
  selectCourseProgress,
  ({overallProgress}) => overallProgress
);

/************************************************************************
 * can Go Back
 * > boolean
 */
export const canGoBack = createSelector(
  selectCourseState,
  ({courseProgress}) => courseProgress?.canGoBack
);

/************************************************************************
 * Course Completion Timer
 * > {totalTime, completedTime}
 */
export const courseCompletionTimer = createSelector(
  selectCourseProgress,
  ({totalTime, completedTime}) => ({
    totalTime,
    completedTime
  })
);

/************************************************************************
 * Select Language
 * > string
 */
export const selectLanguage = createSelector(
  selectCourseState,
  ({courseProgress}) => courseProgress?.languageCode
);

/************************************************************************
 * load images for slide
 */
export const loadImages = createSelector(
  selectCourseState,
  (courseState) => courseState.images
);

/************************************************************************
 * Select Languages
 */
export const selectLanguages = createSelector(
  selectCourseState,
  (courseState) => courseState.courseLanguages
);

/************************************************************************
 * Is Next Tile Unlocked
 */
export const isNextTileUnlocked = createSelector(
  selectCourseState,
  selectCourseProgress,
  ({course}, {asi: activeSlideId, slideData}) => {
    /*if (course ) {
      const activeSlideData = slideData[activeSlideId] as SlideProgressData;

      if(activeSlideData && activeSlideData.type === CourseSlideType.LEVEL) {
        const tiles = (activeSlideData as LevelProgressData).tiles;

        return Object
          .keys(tiles)
          .map(key => tiles[key])
          .some(tile => !tile.isSlideUnlocked && !tile.tileLockSequentially)
      }

      return false;
    }*/

    return false;

  }
);

/************************************************************************
 * Select Language State
 */
export const showLanguageSelection = createSelector(
  selectCourseState,
  (courseState) => courseState.showLanguageSelection
);
