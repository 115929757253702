import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';

@Component({
    selector: 'sc-next-icon',
    templateUrl: './next-icon.component.html',
    styleUrls: ['./next-icon.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true
})
export class NextIconComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
