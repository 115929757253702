import {ChangeDetectionStrategy, Component, ElementRef, Input, ViewChild} from '@angular/core';
import { NgIf } from '@angular/common';
import { TooltipModule } from 'primeng/tooltip';
import { TranslationPipe } from '../../../../../@core/pipes/translation.pipe';

@Component({
    selector: 'sc-audio-narrator',
    templateUrl: './audio-narrator.component.html',
    styleUrls: ['./audio-narrator.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [NgIf, TooltipModule, TranslationPipe]
})
export class AudioNarratorComponent {

  @Input() audioUrl: string;
  @Input() selectedLanguage: string | null;
  @ViewChild('audioElement', {static: false}) public _audioRef: ElementRef;
  private audio: HTMLMediaElement;


  @Input() set volume(volume: number | null) {
    if (this.audio && volume) {
      this.audio.volume = volume / 100;
    } else if (this.audio && !volume) {
      this.audio.volume = 0;
    }
  }

  public ngAfterViewInit() {
    this.audio = this._audioRef.nativeElement;
  }

  public playNarration() {
    if (this.audio) {
      if (this.audio.readyState >= 2) {
        this.audio.play();
      }
    }
  }

  public pause(): void {
    if (this.audio) {
      this.audio.pause();
    }
  }

  public get paused(): boolean {
    if (this.audio) {
      return this.audio.paused;
    } else {
      return true;
    }
  }

  public endNarration() {
    if (this.audio) {
      this.audio.pause();
    }
  }
}
