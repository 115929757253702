import { ChangeDetectionStrategy, Component, input, InputSignal } from '@angular/core';
import { GraphicItem } from '@models/asset.model';


@Component({
  selector: 'sg-graphic-section',
  standalone: true,
  imports: [],
  templateUrl: './graphic-section.component.html',
  styleUrl: './graphic-section.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GraphicSectionComponent {
  graphic: InputSignal<GraphicItem> = input.required();
}
