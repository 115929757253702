import { Injectable } from '@angular/core';
import {cmi, CompletionStatus, ExitType, ScormAPI, SuccessStatus} from '@models/scorm.model';
import { CourseProgress } from '@models/course-progress.model';
import { Observable, of } from 'rxjs';
import { environment } from '@env/environment';

@Injectable()
export class ScormService {

  get scormAPI(): ScormAPI | null {
    return this._scormAPI;
  }

  get isScormEnv() {
    return !!this._scormAPI;
  }

  private readonly _scormAPI: ScormAPI | null;

  constructor() {
    this._scormAPI = this._findScormApi();
    if(this.isScormEnv) {
      this.init();
    }
  }

  /**
   * Scorm init to initialize the scorm api
   */
  init() {
    if (!this._scormAPI) {
      throw new Error('LMS API not found!');
    }
    const initStatus = this._scormAPI.Initialize('');
    console.log('initStatus', initStatus)

    this._updateLearningStatus();
  }


  finish() {
    this._scormAPI?.Terminate('');
  }

  /**
   * get/set completion Status
   * @param completion_status
   */
  completionStatus(completion_status?: CompletionStatus): CompletionStatus {
    if(completion_status) {
      return this._scormAPI!.SetValue(cmi.completion_status, completion_status) as CompletionStatus;
    }

    return this._scormAPI!.GetValue(cmi.completion_status) as CompletionStatus;
  }

  successStatus(success_status?: SuccessStatus): SuccessStatus {
    if(success_status) {
      return this._scormAPI!.SetValue(cmi.success_status, success_status) as SuccessStatus;
    }

    return this._scormAPI!.GetValue(cmi.success_status) as SuccessStatus;
  }



  getData(): Observable<CourseProgress> {
    const data = (this._scormAPI as ScormAPI).GetValue(cmi.suspend_data);
    const dataToReturn = data ? JSON.parse(data) : null;

    console.log('reading...', data.length)

    return of(dataToReturn);
  }

  setData(progressData: CourseProgress) {
    // console.log('progressData', progressData);
    const dataToSave = JSON.stringify(progressData);
    const setData = (this._scormAPI as ScormAPI).SetValue(cmi.suspend_data, dataToSave);

    console.log('saving...', setData);
    console.log(dataToSave?.length, setData);
  }

  commit() {
    const committed = (this._scormAPI as ScormAPI).Commit('');
    console.log('committed? ', committed)
  }

  exit() {
    return this._scormAPI!.SetValue(cmi.exit, ExitType.normal) as ExitType;
  }

  completeCourse() {
    this.completionStatus(CompletionStatus.completed);
    this.successStatus(SuccessStatus.passed);

    this.commit();
  }

  /**
   * Update Learning statues
   * @private
   */
  private _updateLearningStatus() {

    // Update Completion Status
    const completionStatus = this.completionStatus();
    console.log('status', completionStatus)

    if (!completionStatus || completionStatus === CompletionStatus.unknown || completionStatus === CompletionStatus.not_attempted) {
      const lMSSetValue = this.completionStatus(CompletionStatus.incomplete);
      console.log('lMSSetValue', lMSSetValue)
    }

    // Update Success Status
    const successStatus = this.successStatus();
    console.log('successStatus', successStatus);

    if (!successStatus || successStatus === SuccessStatus.unknown) {
      const lMSSetValue = this.successStatus(SuccessStatus.unknown);
      console.log('lMSSetValue', lMSSetValue)
    }

    this.commit();
  }


  /**
   * Find SCORM API
   * @private
   */
  private _findScormApi(win: any = window): ScormAPI | null {
    /*if (win.API_1484_11) {
      return environment.production ? win.API_1484_11 : win.API()
    }*/
    if (win.parent && win.parent !== win) {
      return this._findScormApi(win.parent)
    }
    if (window.opener && typeof window.opener !== 'undefined') {
      return this._findScormApi(window.opener)
    }

    return null;
  }
}
