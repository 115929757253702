import {ChangeDetectionStrategy, Component, input, InputSignal, output} from '@angular/core';
import {CheckboxProgress, SlideProgress} from "@models/course-progress-data-types.model";
import {CheckboxSlide} from "@models/slide.model";
import {LayoutTemplateComponent} from "../layout-slide/layout-template/layout-template.component";
import {CheckboxComponent} from "../../../common/checkbox/checkbox.component";
import {FormsModule} from "@angular/forms";

@Component({
  selector: 'sg-checkbox-slide',
  standalone: true,
  imports: [
    LayoutTemplateComponent,
    CheckboxComponent,
    FormsModule,
  ],
  templateUrl: './checkbox-slide.component.html',
  styleUrl: './checkbox-slide.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CheckboxSlideComponent {

  checkboxSlide: InputSignal<CheckboxSlide> = input.required();
  checkboxProgress: InputSignal<CheckboxProgress> = input.required(
    {
      transform: (checkbox) => {
        this.check = checkbox.checked
        return checkbox
      }
    }
  );

  saveProgress = output<SlideProgress>();
  check: boolean;

  toggleCheck(checked: boolean) {
    this.checkboxProgress().isSlideUnlocked = checked;
    this.checkboxProgress().slideCompleted = checked;
    this.checkboxProgress().checked = checked;
    this.saveProgress.emit(this.checkboxProgress())
  }
}
