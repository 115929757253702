import {GraphicItem} from '@models/asset.model';

/**
 * Enum representing the main categories of slides.
 */
export enum SlideCategory {
  QUESTION = 'Question',
  MEDIA = 'Media',
  GROUP = 'Group',
  IMAGE = 'Image',
  CHECKBOX = 'Checkbox',
  LAYOUT = 'Layout',
}

export type SlideType =
  | QuestionSlide
  | CheckboxSlide
  | GroupSlide
  | LayoutSlide
  | MediaSlide

export interface Slides {
  [id: string]: SlideType;
}

export interface RichText {
  html: string;
  text: string;
}

/**
 * Interface representing the settings and data for text-to-speech functionality.
 * - `enableTTS`: A boolean flag indicating whether text-to-speech is enabled. Default is `false`.
 * - `enableCustomTTS`: A boolean flag indicating whether custom text-to-speech is enabled. Default is `false`.
 * - `ttsAudioUrl`: The URL of the audio file generated by the text-to-speech. Default is `null`.
 * - `ttsScript`: The script or text to be converted into speech by the text-to-speech engine. Default is `null`.
 */
export interface TextToSpeech {
  enableTTS: boolean;
  enableCustomTTS: boolean;
  ttsAudioUrl: string;
  ttsScript: string;
}

/**
 * Interface defining the structure of a slide object.
 */
export interface Slide {
  index: number;
  id: string;
  category: SlideCategory;
  subCategory?: MediaCategory | GroupCategory | LayoutCategory;
  title: string;
  link: string[];
  lastItemInLink: boolean;
  firstItemInLink: boolean;
}

/** Slide Types **/

/**
 * Layout Slide
 */
export enum LayoutCategory {
  END = 'End',
  TITLE = 'Title',
}

export enum LayoutType {
  TEXT_LEFT = 'text_left',
  TEXT_RIGHT = 'text_right',
  TEXT_TOP = 'text_top',
  TEXT_BOTTOM = 'text_bottom',
  TEXT_FULLSCREEN = 'text_fullscreen',
  IMAGE_FULLSCREEN = 'image_fullscreen',
  GRID = 'grid',
  TILE = 'tile'
}

export enum ImageSize {
  FIT = 'fit',
  FILL = 'fill'
}

export enum FileType {
  LOTTIE = 'application/json',
  IMAGE = 'image/png',
  VIDEO = 'video',
}

export enum PointerType {
  POPUP = 'popup',
  NESTED = 'nested'
}

export interface LayoutSlide extends Slide {
  category: SlideCategory.LAYOUT;
  layoutType: LayoutType;
  graphic?: GraphicItem;
  title: string;
  slideText: RichText;
  textToSpeech: TextToSpeech;
  isFillScreen: boolean;
}

/**
 * Question Slide
 */
export class Answer {
  answer: string;
}

export enum InfoTriggerType {
  CORRECT_ANSWER_BASED = 'correct-answer-based',
  SELECT_FIRST_BASED = 'select-first-based'
}


export interface QuestionSlide extends Slide {
  category: SlideCategory.QUESTION;
  question: string;
  answers: Answer[];
  correctAnswersIndex: number[];
  graphic?: GraphicItem;
  title: string;
  selectAllCorrectAnswers: boolean;
  correctAnswerTooltip: string;
  questionTip: string;
  infoTriggerType?: InfoTriggerType;
}

/**
 * Checkbox Slide
 */
export interface CheckboxSlide extends Slide {
  category: SlideCategory.CHECKBOX;
  layoutType: LayoutType;
  graphic?: GraphicItem;
  conditionText: string;
  hasAdditionalText: boolean;
  additionalText: RichText;
}

/**
 * Group Slide
 */
export enum GroupCategory {
  LEVEL = 'Level',
  HOTSPOT = 'Hotspot',
  POINTER = 'Pointer',
  TILE = 'Tile',
}

export interface GroupSlide extends Slide {
  category: SlideCategory.GROUP;
  content: Slides;
  graphic?: GraphicItem;
}

export interface LevelSlide extends GroupSlide {
  subCategory: GroupCategory.LEVEL;
  content: {
    [key: string]: TileSlide;
  }
  unlockTilesSequentially: boolean;
  layoutType: LayoutType;
  // isGroup = true;
  slideName: string;
  enableInfoText: boolean;
  infoText: string;

}

export interface TileSlide extends GroupSlide {
  subCategory: GroupCategory.TILE;
  isTileLocked: boolean;
  title: string;
}

export interface HotspotSlide extends GroupSlide {
  subCategory: GroupCategory.HOTSPOT;
  content: {
    [key: string]: PointerSlide;
  }
  layoutType: ImageSize;
  slideName: string;
  // title : GroupCategory.HOTSPOT;
  foregroundGraphic: GraphicItem;
}

export interface PointerSlide extends GroupSlide {
  subCategory: GroupCategory.POINTER;
  title: string;
  addContent: boolean;
  layoutType: ImageSize;
  pointerType: PointerType;
  popupText: { html: string, text: string };
  pointerColor: string;
  top: number;
  left: number;
}

/**
 * Media Slide
 */
export enum MediaCategory {
  EXPLAINER = 'Explainer',
  VIDEO = 'Video'
}

export interface MediaData {
  id: string;
  thumbUrl: string;
  fileUrl: string;
  name: string;
  duration: number;
  isProcessed: boolean
  misc?: MediaMisc
}

export interface MediaMisc {
  subtitle?: GraphicItem;
  duration?: number;
}

export interface MediaSlide extends Slide {
  category: SlideCategory.MEDIA;
  data: MediaData | null;
}
