import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Course } from '@models/course.model';
import { environment } from '@env/environment';

@Injectable()
export class CourseService {

  constructor(private _http: HttpClient) {
  }

  /************************************************************************
   * Get course by id
   * @param courseId
   * > Observable<Course>
   * @param languageCode
   */
  getCourse(courseId: string | null, languageCode: string): Observable<Course> {
    if (courseId) {
      return this._http.get(environment.apiBaseUrl + `courses/${courseId}/preview-data`, {
        params: {
          lang: languageCode
        }
      }).pipe(
        map((res: any) => res.data)
      )
    }

    return this._http.get(`./assets/json/data_${languageCode}.json?${new Date().getTime()}`) as Observable<Course>
  }
}
