import {ChangeDetectionStrategy, Component, effect, input, InputSignal, OnInit, output} from '@angular/core';
import {AsyncPipe} from '@angular/common';
import {Observable} from 'rxjs';
import {LayoutSlide, LayoutCategory} from '@models/slide.model';
import {LayoutProgress, SlideProgress} from '@models/course-progress-data-types.model';
import {CourseFacadeService} from '@core/facades/course.facade.service';
import {LayoutTemplateComponent} from './layout-template/layout-template.component';
import {AudioNarratorComponent} from './audio-narrator/audio-narrator.component';

@Component({
  selector: 'sg-layout-slide',
  standalone: true,
  imports: [
    AsyncPipe,
    LayoutTemplateComponent,
    AudioNarratorComponent,
  ],
  templateUrl: './layout-slide.component.html',
  styleUrl: './layout-slide.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LayoutSlideComponent implements OnInit {
  volume$: Observable<number>
  selectedLanguage$: Observable<string>;

  layoutSlide: InputSignal<LayoutSlide> = input.required();
  layoutProgress: InputSignal<LayoutProgress> = input.required();

  saveProgress = output<SlideProgress>();

  constructor(private _courseFacade: CourseFacadeService) {

    this.selectedLanguage$ = this._courseFacade.selectedLanguage$;
    this.volume$ = this._courseFacade.currentVolume$;

    effect(() => {
      if (!this.layoutProgress()?.slideCompleted) {
        const layoutProgressCopy = {...this.layoutProgress()}
        layoutProgressCopy.slideCompleted = true;
        this.saveProgress.emit(layoutProgressCopy)
      }
    })
  }

  ngOnInit(): void {
    if (this.layoutSlide().subCategory == LayoutCategory.END) {
      console.log('completed')
      this._courseFacade.completeCourse();
    }
  }

}
