<section class="player-control-container" *ngIf="(slideDataUnit$ | async) as slideDataUnit"
         (window:keyup.ArrowRight)="navigateToNextSlide(slideDataUnit)"
         (window:keyup.Tab)="navigateToNextSlide(slideDataUnit)"
         (window:keyup.ArrowLeft)="navigateToPreviousSlide()">

  <sc-navigation-control class="back" [isBack]="true"
                         [class.unlocked]="canGoBack$ | async"
                         (click)="navigateToPreviousSlide()"
                         [tooltipPosition]="'bottom'"
                         [pTooltip]="'backSlideTooltip' | translation : (selectedLanguage$ | async)" [showDelay]="1000">
  </sc-navigation-control>

  <sc-course-progress-info [courseTitle]="title" [skipMode]="navigationFacade.skipMode()"/>
  <sc-base-controls/>

  <sc-navigation-control [class.unlocked]="slideDataUnit.slideProgress.isSlideUnlocked"
                         [category]="slideDataUnit.category"
                         [subCategory]="slideDataUnit.subCategory"
                         [isSlideUnlocked]="slideDataUnit.slideProgress.isSlideUnlocked"
                         (click)="navigateToNextSlide(slideDataUnit)"
                         [tooltipPosition]="'bottom'"
                         [pTooltip]="'nextSlideTooltip' | translation : (selectedLanguage$ | async)"
                         [showDelay]="1000">
  </sc-navigation-control>
</section>

