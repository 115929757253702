<div class="controls-container">
  <div class="controls">
    <div class="control-items">
      <sc-player-icon [playingStatus]="playingStatus"
                      (click)="togglePlayPause()">
      </sc-player-icon>
      <sc-player-current-timestamp [currentTime]="currentTime!">
      </sc-player-current-timestamp>
      <sc-player-timeline [timePercentage]="(currentTime! / duration) * 100"
                          [unlockedTimePercentage]="(unlockedTime / duration) * 100"
                          (onChange)="updateTime.emit($event)">
      </sc-player-timeline>
      <sc-player-timestamp [duration]="duration">
      </sc-player-timestamp>
    </div>
  </div>
</div>
