import {createReducer, on} from '@ngrx/store';

import * as courseActions from './course.actions';
import {ActiveSlideIndexLevel, CourseState} from '@models/course-state.model';
import {calculateCompletedTime} from '@root-store/course/course-store-utils';

export const courseFeatureKey = 'courseState';

export const initialState: CourseState = new CourseState();

export const courseReducer = createReducer(
  initialState,

  /************************************************************************
   * init
   */
  on(courseActions.resumeCourse, (state, {courseProgress}) => {
      return {
        ...state,
        courseProgress
      };
    }
  ),
  /************************************************************************
   * Get course by id
   */
  on(courseActions.getCourseById, (state) => {
      return {
        ...state,
        isCourseLoading: true
      };
    }
  ),
  on(courseActions.getCourseByIdSuccess, (state, {course, languageCode, asi, isUnlocked}) => {
      const {courseProgress} = state;
      return {
        ...state,
        ...new CourseState(course, courseProgress, languageCode, asi, isUnlocked),
        isCourseLoaded: true,
      };
    }
  ),

  /************************************************************************
   * Get Languages
   */
  on(courseActions.getLanguagesSuccess, (state, {data}) => {
      return {
        ...state,
        courseLanguages: data.languages,
        // course: {
        //   config: data.config
        // }
      };
    }
  ),


  /************************************************************************
   * Save Slide Progress Data
   */
  on(courseActions.updateSlideProgressData, (state, {slideProgressData, slideProgress}) => {

      const {courseProgress} = state;
      const {completedTime} = courseProgress;
      const completedTimeToUpdate = calculateCompletedTime(slideProgress, completedTime);

      return {
        ...state,
        courseProgress: {
          ...courseProgress,
          completedTime: completedTimeToUpdate,
          slideData: {
            ...courseProgress.slideData,
            ...slideProgressData
          }
        }
      };
    }
  ),

  /************************************************************************
   * Set Active Link Map (actLM)
   */
  on(courseActions.setActiveLinkMap, (state, {asi, cpi}) => {

      const {courseProgress} = state;

      return {
        ...state,
        courseProgress: {
          ...courseProgress,
          cpi,
          asi
        }
      } as CourseState;
    }
  ),

  /************************************************************************
   * Disable back when moving to next slide
   */
  on(courseActions.initNextNavigate, (state) => {
    const {courseProgress} = state;
    return {
      ...state,
      courseProgress: {
        ...courseProgress,
        canGoBack: false,
      }
    };
  }),


  /************************************************************************
   * Complete Course
   */
  on(courseActions.completeCourse, (state) => {

      const {courseProgress} = state;

      return {
        ...state,
        activeSlideIndexLevel: ActiveSlideIndexLevel.Root,
        courseProgress: {
          ...courseProgress,
          isCompleted: true,
          completedTime: state.courseProgress.totalTime,

        }
      } as CourseState;
    }
  ),

  /************************************************************************
   * Show Language Selection
   */
  on(courseActions.showLanguageSelection, (state) => {
      return {
        ...state,
        showLanguageSelection: true
      }
    }
  ),

  /************************************************************************
   * Show Course
   */
  on(courseActions.showCourse, (state) => {
      return {
        ...state,
        showLanguageSelection: false
      }
    }
  ),
);
