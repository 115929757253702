import {ChangeDetectionStrategy, Component, input, InputSignal, output} from '@angular/core';
import {GroupProgress, SlideProgress} from "@models/course-progress-data-types.model";
import {GroupCategory, GroupSlide} from "@models/slide.model";
import {LevelSlideComponent} from "./level-slide/level-slide.component";
import {HotspotSlideComponent} from "./hotspot-slide/hotspot-slide.component";

@Component({
  selector: 'sg-group-slide',
  standalone: true,
  imports: [
    LevelSlideComponent,
    HotspotSlideComponent
  ],
  templateUrl: './group-slide.component.html',
  styleUrl: './group-slide.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GroupSlideComponent {

  groupSlide: InputSignal<GroupSlide> = input.required();
  groupProgress: InputSignal<GroupProgress> = input.required();

  saveProgress = output<GroupProgress | SlideProgress>();

  protected readonly GroupCategory = GroupCategory;
}
