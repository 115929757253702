{
  "en": {
    "completed": "Completed",
    "timeRemaining": "Minute(s) remaining",
    "backSlideTooltip": "Previous Slide (←)",
    "nextSlideTooltip": "Next Slide (→)",
    "volume": "Volume (+ / -)",
    "language": "Select Language",
    "fullscreen": "Fullscreen (F)",
    "subtitle": "Toggle Subtitle (S)",
    "closePopup":"Close Popup",
    "InvalidPrompt": "Invalid Answer, Try again!",
    "playNarrator": "Play audio narration"
  },
  "sv": {
    "completed": "Avslutad",
    "timeRemaining": "minuter återstående",
    "backSlideTooltip": "Föregående sida (←)",
    "nextSlideTooltip": "Nästa sida (→)",
    "volume": "Volym (+ / -)",
    "language": "Välj språk",
    "fullscreen": "Fullskärmsläge (F)",
    "subtitle": "Undertexter (S)",
    "closePopup":"Stäng popup",
    "InvalidPrompt": "Ogiltigt svar, försök igen!",
    "playNarrator": "Spela ljudberättelse"
  },
  "ja": {
    "completed": "完了",
    "timeRemaining": "残り分",
    "backSlideTooltip": "前のスライド (←)",
    "nextSlideTooltip": "次のスライド (→)",
    "volume": "音量 (+ / -)",
    "language": "言語を選択する",
    "fullscreen": "全画面表示 (F)",
    "subtitle": "字幕を切り替え (S)",
    "closePopup":"ポップアップを閉じる",
    "InvalidPrompt": "無効な回答です。もう一度お試しください。",
    "playNarrator": "音声ナレーションを再生する"
  },
  "fr": {
    "completed": "complété",
    "timeRemaining": "Minutes restantes",
    "backSlideTooltip": "Diapositive précédente (←)",
    "nextSlideTooltip": "Diapositive suivante (→)",
    "volume": "Volume (+ / -)",
    "language": "Choisir la langue",
    "fullscreen": "Plein écran (F)",
    "subtitle": "Basculer les sous-titres (S)",
    "closePopup":"Fermer la fenêtre contextuelle",
    "InvalidPrompt": "Réponse invalide, réessayez!",
    "playNarrator": "Lire la narration audio"
  },
  "pt-br": {
    "completed": "concluída",
    "timeRemaining": "Minutos restantes",
    "backSlideTooltip": "Slide anterior (←)",
    "nextSlideTooltip": "Próximo slide (→)",
    "volume": "Volume (+ / -)",
    "language": "Selecione o idioma",
    "fullscreen": "Tela cheia (F)",
    "subtitle": "Alternar legenda (S)",
    "closePopup":"Fechar pop-up",
    "InvalidPrompt": "concluída",
    "playNarrator": "Reproduzir narração de áudio"
  },
  "ko": {
    "completed": "완전한",
    "timeRemaining": "남은 시간(분)",
    "backSlideTooltip": "이전 슬라이드 (←)",
    "nextSlideTooltip": "다음 슬라이드 (→)",
    "volume": "용량 (+ / -)",
    "language": "언어 선택",
    "fullscreen": "전체 화면 (F)",
    "subtitle": "자막 전환 (S)",
    "closePopup":"팝업 닫기",
    "InvalidPrompt": "잘못된 답변입니다. 다시 시도하세요!",
    "playNarrator": "오디오 내레이션 재생"
  },
  "de": {
    "completed": "abgeschlossen",
    "timeRemaining": "Minuten verbleibend",
    "backSlideTooltip": "Vorherige Folie (←)",
    "nextSlideTooltip": "Nächste Folie (→)",
    "volume": "Volumen (+ / -)",
    "language": "Sprache auswählen",
    "fullscreen": "Ganzer Bildschirm (F)",
    "subtitle": "Untertitel umschalten (S)",
    "closePopup":"Popup schließen",
    "InvalidPrompt": "Ungültige Antwort, versuchen Sie es erneut!",
    "playNarrator": "Audiokommentar abspielen"
  },
  "cs": {
    "completed": "dokončeno",
    "timeRemaining": "Zbývající minuty",
    "backSlideTooltip": "Předchozí snímek (←)",
    "nextSlideTooltip": "Další snímek (→)",
    "volume": "Hlasitost (+ / -)",
    "language": "Zvolte jazyk",
    "fullscreen": "Celá obrazovka (F)",
    "subtitle": "Přepnout titulky (S)",
    "closePopup":"Close",
    "InvalidPrompt": "Neplatná odpověď, zkuste to znovu!",
    "playNarrator": "Přehrát zvukový vyprávění"
  },
  "zh-tw": {
    "completed": "完全的",
    "timeRemaining": "剩餘分鐘數",
    "backSlideTooltip": "上一張幻燈片 (←)",
    "nextSlideTooltip": "下一張幻燈片 (→)",
    "volume": "體積 (+ / -)",
    "language": "選擇語言",
    "fullscreen": "全屏 (F)",
    "subtitle": "切換字幕 (S)",
    "closePopup":"關閉彈出視窗",
    "InvalidPrompt": "答案無效，請重試！",
    "playNarrator": "播放音訊旁白"
  },
  "es": {
    "completed": "terminada",
    "timeRemaining": "Minutos restantes",
    "backSlideTooltip": "Diapositiva anterior (←)",
    "nextSlideTooltip": "La siguiente diapositiva (→)",
    "volume": "Volumen (+ / -)",
    "language": "Seleccione el idioma",
    "fullscreen": "Pantalla completa (F)",
    "subtitle": "Alternar subtítulo (S)",
    "closePopup":"Cerrar elemento emergente",
    "InvalidPrompt": "Respuesta inválida, inténtalo de nuevo!",
    "playNarrator": "Reproducir narración de audio"
  },
  "zh": {
    "completed": "完全的",
    "timeRemaining": "剩余分钟数",
    "backSlideTooltip": "上一张幻灯片 (←)",
    "nextSlideTooltip": "下一张幻灯片 (→)",
    "volume": "体积 (+ / -)",
    "language": "选择语言",
    "fullscreen": "全屏 (F)",
    "subtitle": "切换字幕 (S)",
    "closePopup":"关闭弹出窗口",
    "InvalidPrompt": "答案无效，请重试！",
    "playNarrator": "播放音频旁白"
  },
  "nl": {
    "completed": "voltooid",
    "timeRemaining": "Minuten resterend",
    "backSlideTooltip": "Vorige dia (←)",
    "nextSlideTooltip": "Volgende dia (→)",
    "volume": "Volume (+ / -)",
    "language": "Selecteer Taal",
    "fullscreen": "Volledig scherm (F)",
    "subtitle": "Schakel ondertiteling in (S)",
    "closePopup":"Pop-up sluiten",
    "InvalidPrompt": "Ongeldig antwoord, probeer het opnieuw!",
    "playNarrator": "Speel gesproken tekst af"
  },
  "ru": {
    "completed": "завершенный",
    "timeRemaining": "Минуты осталось",
    "backSlideTooltip": "Предыдущий слайд (←)",
    "nextSlideTooltip": "Следующий слайд (→)",
    "volume": "Объем (+ / -)",
    "language": "Выберите язык",
    "fullscreen": "Полноэкранный (F)",
    "subtitle": "Переключить субтитры (S)",
    "closePopup":"Закрыть всплывающее окно",
    "InvalidPrompt": "Неверный ответ. Попробуйте еще раз!",
    "playNarrator": "Воспроизвести аудиозапись"
  },
  "it": {
    "completed": "completato",
    "timeRemaining": "Minuti rimanenti",
    "backSlideTooltip": "Diapositiva precedente (←)",
    "nextSlideTooltip": "Prossima diapositiva (→)",
    "volume": "Volume (+ / -)",
    "language": "Seleziona la lingua",
    "fullscreen": "A schermo intero (F)",
    "subtitle": "Attiva/disattiva sottotitoli (S)",
    "closePopup":"Chiudi il pop-up",
    "InvalidPrompt": "Risposta non valida, riprova!",
    "playNarrator": "Riproduci la narrazione audio"
  },
  "es-mx": {
    "completed": "terminada",
    "timeRemaining": "Minutos restantes",
    "backSlideTooltip": "Diapositiva anterior (←)",
    "nextSlideTooltip": "La siguiente diapositiva (→)",
    "volume": "Volumen (+ / -)",
    "language": "Seleccione el idioma",
    "fullscreen": "Pantalla completa (F)",
    "subtitle": "Alternar subtítulo (S)",
    "closePopup":"Cerrar elemento emergente",
    "InvalidPrompt": "Respuesta no válida, ¡inténtalo de nuevo!",
    "playNarrator": "Reproducir narración de audio"
  },
  "ar": {
    "completed": "مكتمل",
    "timeRemaining": "دقائق متبقية",
    "backSlideTooltip": "الشريحة السابقة (←)",
    "nextSlideTooltip": "الشريحة التالية (→)",
    "volume": "مقدار (+ / -)",
    "language": "اختار اللغة",
    "fullscreen": "تكبير الشاشة (F)",
    "subtitle": "تبديل العنوان الفرعي (S)",
    "closePopup":"إغلاق النافذة المنبثقة",
    "InvalidPrompt": "إجابة غير صالحة، حاول مرة أخرى!",
    "playNarrator": "تشغيل السرد الصوتي"
  },
  "tr": {
    "completed": "Tamamlandı",
    "timeRemaining": "Dakikalar kaldı",
    "backSlideTooltip": "Bir onceki slayt (←)",
    "nextSlideTooltip": "Sonraki slayt (→)",
    "volume": "Hacim (+ / -)",
    "language": "Dil Seçin",
    "fullscreen": "Tam ekran (F)",
    "subtitle": "Altyazıyı Değiştir (S)",
    "closePopup":"Açılır pencereyi kapat",
    "InvalidPrompt": "Geçersiz Cevap, Tekrar deneyin!",
    "playNarrator": "Sesli anlatımı oynat"
  },
  "da": {
    "completed": "Afsluttet",
    "timeRemaining": "Minut(er) tilbage",
    "backSlideTooltip": "Forrige dias (←)",
    "nextSlideTooltip": "Næste dias (→)",
    "volume": "Lydstyrke (+/-)",
    "language": "Vælg sprog",
    "fullscreen": "Fuld skærm (F)",
    "subtitle": "Skift undertekst (S)",
    "closePopup":"Luk popup",
    "InvalidPrompt": "Ugyldigt svar, prøv igen!",
    "playNarrator": "Afspil lydfortælling"
  },
  "fi": {
    "completed": "Valmis",
    "timeRemaining": "Minuutti(t) jäljellä",
    "backSlideTooltip": "Edellinen dia (←)",
    "nextSlideTooltip": "Seuraava dia (→)",
    "volume": "Äänenvoimakkuus (+ / -)",
    "language": "Valitse kieli",
    "fullscreen": "Koko näyttö (F)",
    "subtitle": "Vaihda tekstitys (S)",
    "closePopup":"Sulje ponnahdusikkuna",
    "InvalidPrompt": "Virheellinen vastaus, yritä uudelleen!",
    "playNarrator": "Toista ääniselostus"
  },
  "nb": {
    "completed": "Fullført",
    "timeRemaining": "Minutt(er) igjen",
    "backSlideTooltip": "Forrige lysbilde (←)",
    "nextSlideTooltip": "Neste lysbilde (→)",
    "volume": "Volum (+ / -)",
    "language": "Velg språk",
    "fullscreen": "Fullskjerm (F)",
    "subtitle": "Slå undertekst (S)",
    "closePopup":"Lukk popup",
    "InvalidPrompt": "Ugyldig svar, prøv igjen!",
    "playNarrator": "Spill lydfortelling"
  },
  "ro-ro": {
    "completed": "Efectuat",
    "timeRemaining": "Minut(e) rămas(e)",
    "backSlideTooltip": "Slide anterior (←)",
    "nextSlideTooltip": "Următorul diapozitiv (→)",
    "volume": "Volum (+ / -)",
    "language": "Selecteaza limba",
    "fullscreen": "Ecran complet (F)",
    "subtitle": "Comutați subtitrare (S)",
    "closePopup":"Închideți pop-up",
    "InvalidPrompt": "Răspuns nevalid, încercați din nou!",
    "playNarrator": "Redați narațiune audio"
  }
}
