import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Timer } from '@models/common.model';


@Injectable()
export class TimerService {

  timer: Subject<Timer> = new Subject<Timer>();

  constructor() { }

  initTimer() {

  }
}
