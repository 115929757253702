import {
  ChangeDetectionStrategy,
  Component, effect,
  inject,
  input,
  InputSignal,
  OnInit,
  output,
  WritableSignal
} from '@angular/core';
import { MediaProgress, SlideProgress } from '@models/course-progress-data-types.model';
import { MediaSlide } from '@models/slide.model';
import { VideoPlayerComponent } from '../../../common/video-player/video-player.component';
import { AsyncPipe } from '@angular/common';
import { Observable } from 'rxjs';
import { CourseFacadeService } from '@core/facades/course.facade.service';

@Component({
  selector: 'sg-media-slide',
  standalone: true,
  imports: [
    VideoPlayerComponent,
    AsyncPipe
  ],
  templateUrl: './media-slide.component.html',
  styleUrl: './media-slide.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MediaSlideComponent implements OnInit {
  mediaSlide: InputSignal<MediaSlide> = input.required();
  mediaProgress: InputSignal<MediaProgress> = input.required();
  saveProgress = output<SlideProgress>();
  volume$: Observable<number>

  private _courseFacade = inject(CourseFacadeService)
  subtitle: WritableSignal<boolean>;

  constructor() {
    effect(() => {
      if (this.mediaSlide() && this.mediaProgress()) {
        if (this.mediaSlide().data &&
          !this.mediaSlide().data?.isProcessed &&
          this.mediaProgress().slideCompleted &&
          this.mediaProgress().currentTime !== this.mediaProgress().duration) {
          let progress = structuredClone(this.mediaProgress());
          progress.currentTime = this.mediaProgress().duration;
          this.saveProgress.emit(progress);
        } else if(!this.mediaSlide().data && !this.mediaProgress().isSlideUnlocked){
          let progress = structuredClone(this.mediaProgress());
          progress.isSlideUnlocked = true;
          this.saveProgress.emit(progress);
        }
      }
    })
  }

  ngOnInit(): void {
    this.volume$ = this._courseFacade.currentVolume$;
    this.subtitle = this._courseFacade.subtitle;
  }

  onVideoTimeUpdate(time: number) {
    if (!this.mediaProgress().isSlideUnlocked) {
      if (this.mediaProgress().currentTime < time) {
        let progress = structuredClone(this.mediaProgress());
        progress.currentTime = time;
        this.saveProgress.emit(progress)
      }
    }
  }

  /**
   * on video end update unlock status
   */
  onVideoEnd() {
    // if (!this.mediaProgress().isSlideUnlocked) {
    let progress = structuredClone(this.mediaProgress());
    progress.slideCompleted = true
    progress.isSlideUnlocked = true
    this.saveProgress.emit(progress)
    // }
  }

  /**
   * read video play stats
   * @param state
   */
  onVideoPlay(state: { currentTime: number, playing: boolean }) {
    let progress = structuredClone(this.mediaProgress());
    if (progress.currentTime < state.currentTime) {
      progress.currentTime = state.currentTime;
    }
    progress.playing = state.playing;
    this.saveProgress.emit(progress);
  }

}
