import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'sg-incorrect-answer-tooltip',
  templateUrl: './incorrect-answer-tooltip.component.html',
  standalone:true,
  styleUrls: ['./incorrect-answer-tooltip.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IncorrectAnswerTooltipComponent {

}
