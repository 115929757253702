/**
 * Convert duration to remaining time string
 * @param duration
 */

export function convertSecondsToMinuteString(duration: number): string {
  if (isNaN(duration)) {
    return "--:--"
  }
  const _seconds = Math.trunc(duration);

  const minutes = Math.floor((_seconds / 60));
  const seconds = _seconds - (minutes * 60);

  const minStr = String(minutes).padStart(2, '0');
  const secStr = String(seconds).padStart(2, '0');

  return `${minStr}:${secStr}`
}
