<ng-container *ngIf="(selectedLanguage$ | async) as selectedLanguage">
  <sc-volume-control [tooltipPosition]="'bottom'"
                     [pTooltip]="'volume' | translation :selectedLanguage"
                     [showDelay]="1000"/>

  <sc-language-control [tooltipPosition]="'bottom'"
                       (click)="selectLanguage()"
                       (window:keyup.l)="selectLanguage()"
                       [pTooltip]="'language' | translation : selectedLanguage"
                       [showDelay]="1000"/>

  <sc-subtitle-control [tooltipPosition]="'bottom'"
                       [pTooltip]="'subtitle' | translation : selectedLanguage"
                       [showDelay]="1000"
                       (window:keyup.s)="toggleSubtitles()"
                       (click)="toggleSubtitles()"/>

  <sc-fullscreen-control [tooltipPosition]="'bottom'"
                         [pTooltip]="'fullscreen' | translation : selectedLanguage"
                         [showDelay]="1000"
                         [isFullscreen]="isFullscreen"
                         (window:keyup.f)="fullscreen()"
                         (click)="fullscreen()"/>
</ng-container>

