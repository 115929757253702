import {animate, group, query, state, style, transition, trigger} from '@angular/animations';

const animationStyle = '250ms ease-in'

export const left = [
  style({transform: 'translateX(-100%)', opacity: 0}),
  animate(animationStyle, style({transform: 'translateX(0%)', opacity: 1}))

];

export const right = [
  style({transform: 'translateX(100%)', opacity: 0}),
  animate(animationStyle, style({transform: 'translateX(0%)', opacity: 1}))
];

export const fade = [
  state('hide', style({
    opacity: '0',
  })),
  state('show', style({
    opacity: '1',
  })),
  transition('hide => show', animate(animationStyle)),
  transition('show => hide', animate(animationStyle))
]
