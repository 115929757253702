@if (tileSlide().graphic; as tileGraphic) {
  @if (tileGraphic.fileType === 'application/json') {
    <sg-animation [class.tileLock]="isLock" [url]="tileGraphic.fileUrl"></sg-animation>
  } @else {
    <sg-base [class.tileLock]="isLock" [tileIndex]="'tile' + tileIndex" [url]="tileGraphic.fileUrl"></sg-base>
  }
} @else {
  <sg-base/>
}

@if (empty()) {
  <sg-events lottieAnimation="exclamation.json"/>
} @else {
  @if (isLock && !isUnlocked) {
    <sg-events [lottieAnimation]="'lock.json'"/>
  }
  @if (isUnlocked) {
    <sg-events [lottieAnimation]="'check-tile.json'"/>
  }
}

<div class="tile-title" [ngClass]="showTitle ? 'tile-title-edit' : ''">
  {{ tileSlide().title }}
</div>

