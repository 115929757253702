<svg width="297" height="160" viewBox="0 0 297 160" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path fill-rule="evenodd" clip-rule="evenodd" d="M149.12 6.55551L295.096 81.7615C296.144 82.3011 296.554 83.5859 296.014 84.6312C295.809 85.0278 295.484 85.35 295.086 85.5525L149.109 159.728C148.501 160.037 147.781 160.037 147.173 159.728L1.19677 85.5525C0.146491 85.0188 -0.271497 83.7363 0.26317 82.688C0.466001 82.2903 0.78885 81.9662 1.18614 81.7615L147.163 6.55551C147.777 6.23918 148.506 6.23918 149.12 6.55551Z" fill="#D8D8D8"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M149.699 6.8527L295.097 81.7611C296.145 82.3007 296.556 83.5855 296.015 84.6308C295.81 85.0274 295.485 85.3497 295.087 85.5521L149.693 159.432C149.168 159.699 148.526 159.49 148.258 158.966C148.182 158.816 148.142 158.651 148.142 158.483V7.79908C148.142 7.21089 148.62 6.73407 149.209 6.73407C149.38 6.73407 149.547 6.77475 149.699 6.8527Z" fill="#BCBBBB"/>
  <path d="M148.389 77.3833H0V83.9706H148.389V77.3833Z" fill="#D8D8D8"/>
  <path d="M296.238 77.3833H148.389V83.9706H296.238V77.3833Z" fill="#BCBBBB"/>
    @if(url){
      <path fill-rule="evenodd" clip-rule="evenodd" d="M149.112 0L295.096 75.21C296.141 75.7487 296.552 77.0313 296.012 78.0749C295.807 78.4708 295.483 78.7924 295.085 78.9946L149.101 153.174C148.494 153.483 147.775 153.483 147.168 153.174L1.18368 78.9946C0.13507 78.4617 -0.282223 77.1814 0.25164 76.1348C0.454141 75.7378 0.776447 75.4143 1.17306 75.21L147.157 6.98146e-08L149.112 0Z"  [attr.fill]="'url(#'+tileIndex+')'"/>
      <defs>
        <pattern [id]="tileIndex" patternUnits="userSpaceOnUse" width="100%" height="100%" x="0" y="0" >
          <image [attr.xlink:href]="url" x="0" y="0" width="100%" height="100%" alt="" preserveAspectRatio="xMidYMid slice"/>
        </pattern>
      </defs>
    } @else {
      <path fill-rule="evenodd" clip-rule="evenodd" d="M149.112 0L295.096 75.21C296.141 75.7487 296.552 77.0313 296.012 78.0749C295.807 78.4708 295.483 78.7924 295.085 78.9946L149.101 153.174C148.494 153.483 147.775 153.483 147.168 153.174L1.18368 78.9946C0.13507 78.4617 -0.282223 77.1814 0.25164 76.1348C0.454141 75.7378 0.776447 75.4143 1.17306 75.21L147.157 6.98146e-08L149.112 0Z"  fill="black"/>
    }
</svg>
