import {
  Component,
  Input,
} from '@angular/core';
import { NgIf, NgSwitch, NgSwitchCase } from '@angular/common';
import { VideoTimerComponent } from './video-timer/video-timer.component';
import { EndIconComponent } from './end-icon/end-icon.component';
import { NextIconComponent } from './next-icon/next-icon.component';
import { BackIconComponent } from './back-icon/back-icon.component';
import {GroupCategory, LayoutCategory, MediaCategory, SlideCategory} from '@models/slide.model';
import {
  NotCompletedIconComponent
} from "@core/layout/player-controls/navigation-control/not-completed-icon/not-completed-icon.component";

@Component({
    selector: 'sc-navigation-control',
    templateUrl: './navigation-control.component.html',
    styleUrls: ['./navigation-control.component.scss'],
    standalone: true,
  imports: [NgIf, NgSwitch, NgSwitchCase, VideoTimerComponent, EndIconComponent, NextIconComponent, BackIconComponent, NotCompletedIconComponent]
})
export class  NavigationControlComponent {

  @Input() isBack?: boolean;
  @Input() category: SlideCategory;
  @Input() subCategory: GroupCategory | LayoutCategory | MediaCategory | undefined;
  @Input() isSlideUnlocked: boolean;

  slideCategory = SlideCategory;
  protected readonly LayoutCategory = LayoutCategory;
}
