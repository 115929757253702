import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'sg-correct-answer-tooltip',
  templateUrl: './correct-answer-tooltip.component.html',
  standalone:true,
  styleUrls: ['./correct-answer-tooltip.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CorrectAnswerTooltipComponent {

}
