@if (slideDataUnit(); as activeSlide) {
  <section class="slide-container" [@slide]="activeSlide.slide.index">
    @switch (activeSlide.category) {
      @case (SlideCategory.LAYOUT) {
        <sg-layout-slide [layoutSlide]="$any(activeSlide.slide)"
                         [layoutProgress]="$any(activeSlide.slideProgress)"
                         (saveProgress)="saveProgress.emit($event)"/>
      }
      @case (SlideCategory.GROUP) {
        <sg-group-slide [groupSlide]="$any(activeSlide.slide)"
                        [groupProgress]="$any(activeSlide.slideProgress)"
                        (saveProgress)="saveProgress.emit($event)"/>
      }
      @case (SlideCategory.CHECKBOX) {
        <sg-checkbox-slide [checkboxSlide]="$any(activeSlide.slide)"
                           [checkboxProgress]="$any(activeSlide.slideProgress)"
                           (saveProgress)="saveProgress.emit($event)"/>
      }
      @case (SlideCategory.QUESTION) {
        <sg-question-slide [questionSlide]="$any(activeSlide.slide)"
                           [questionProgress]="$any(activeSlide.slideProgress)"
                           (saveProgress)="saveProgress.emit($event)"/>
      }
      @case (SlideCategory.MEDIA) {
        <sg-media-slide [mediaSlide]="$any(activeSlide.slide)"
                        [mediaProgress]="$any(activeSlide.slideProgress)"
                        (saveProgress)="saveProgress.emit($event)"/>
      }
    }
  </section>
}

<!--<ng-container *ngIf="slideDataUnit as activeSlide">-->
<!--  <section class="slide-container" [@slide]="activeSlide.slide.index">-->
<!--    <ng-container [ngSwitch]="activeSlide.type">-->
<!--      <ng-container *ngSwitchCase="slideType.TITLE">-->
<!--        <cp-title [titleSlide]="$any(activeSlide.slide)"></cp-title>-->
<!--      </ng-container>-->
<!--      <ng-container *ngSwitchCase="slideType.QUESTION">-->
<!--        <sc-question [questionSlide]="activeSlide.slide"-->
<!--                     [questionProgressData]="activeSlide.slideProgress"-->
<!--                     (saveProgress)="saveProgress.emit($event)">-->
<!--        </sc-question>-->
<!--      </ng-container>-->
<!--      <ng-container *ngSwitchCase="slideType.HOTSPOT">-->
<!--        <sc-hotspot [hotspotSlide]="activeSlide.slide"-->
<!--                    [hotspotProgressData]="activeSlide.slideProgress"-->
<!--                    (saveProgress)="saveProgress.emit($event)">-->
<!--        </sc-hotspot>-->
<!--      </ng-container>-->
<!--      <ng-container *ngSwitchCase="slideType.VIDEO">-->
<!--        <sc-video [videoProgressData]="activeSlide.slideProgress"-->
<!--                  [videoSlide]="activeSlide.slide"-->
<!--                  (saveProgress)="saveProgress.emit($event)">-->
<!--        </sc-video>-->
<!--      </ng-container>-->
<!--      <ng-container *ngSwitchCase="slideType.BACKGROUND_VIDEO">-->
<!--        <sc-animated-background [animatedBackgroundProgressData]="activeSlide.slideProgress"-->
<!--                                [animatedBackground]="activeSlide.slide"-->
<!--                                (saveProgress)="saveProgress.emit($event)">-->
<!--        </sc-animated-background>-->
<!--      </ng-container>-->
<!--      <ng-container *ngSwitchCase="slideType.IMAGE">-->
<!--        <sc-image [imageProgressData]="activeSlide.slideProgress"-->
<!--                  [imageSlide]="activeSlide.slide"-->
<!--                  (saveProgress)="saveProgress.emit($event)">-->
<!--        </sc-image>-->
<!--      </ng-container>-->
<!--      <ng-container *ngSwitchCase="slideType.EXPLAINER">-->
<!--        <sc-explainer [explainerProgressData]="activeSlide.slideProgress"-->
<!--                      [explainerSlide]="activeSlide.slide"-->
<!--                      (saveProgress)="saveProgress.emit($event)">-->
<!--        </sc-explainer>-->
<!--      </ng-container>-->
<!--      <ng-container *ngSwitchCase="slideType.LAYOUT">-->
<!--        <sc-layout [layoutProgressData]="activeSlide.slideProgress"-->
<!--                   [layoutSlide]="activeSlide.slide"-->
<!--                   (saveProgress)="saveProgress.emit($event)">-->
<!--        </sc-layout>-->
<!--      </ng-container>-->
<!--      <ng-container *ngSwitchCase="slideType.CHECKBOX">-->
<!--        <sc-checkbox [checkboxSlide]="activeSlide.slide"-->
<!--                     [checkboxProgressData]="activeSlide.slideProgress"-->
<!--                     (saveProgress)="saveProgress.emit($event)">-->
<!--        </sc-checkbox>-->
<!--      </ng-container>-->
<!--      <ng-container *ngSwitchCase="slideType.LEVEL">-->
<!--        <sc-level [levelSlide]="activeSlide.slide"-->
<!--                  [levelProgressData]="activeSlide.slideProgress"-->
<!--                  (saveProgress)="saveProgress.emit($event)">-->
<!--        </sc-level>-->
<!--      </ng-container>-->
<!--      <ng-container *ngSwitchCase="slideType.END">-->
<!--        <sc-slide-completion [endSlide]="activeSlide.slide"-->
<!--                             [endProgressData]="activeSlide.slideProgress">-->
<!--        </sc-slide-completion>-->
<!--      </ng-container>-->
<!--    </ng-container>-->
<!--  </section>-->

<!--</ng-container>-->

