import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import { ConvertSecondsToMinuteStringPipe } from '../../../../../@core/pipes/convert-seconds-to-minute-string.pipe';

@Component({
    selector: 'sc-player-current-timestamp',
    templateUrl: './player-current-timestamp.component.html',
    styleUrls: ['./player-current-timestamp.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [ConvertSecondsToMinuteStringPipe]
})
export class PlayerCurrentTimestampComponent {
  @Input() currentTime: number;
}
