@if (graphic(); as graphic) {
  @if (graphic.fileType === 'video') {
    <video width="100%"
           autoplay
           loop
           [src]="graphic.fileUrl"
           playsinline>
    </video>
  } @else {
    <div class="image-container"
         [style.background-image]="'url(' + graphic.fileUrl + ')'"></div>
    <!--[style.backgroundSize]="graphic.isFillScreen? 'cover' : 'contain'"-->
  }
}
