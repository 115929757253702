import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';

@Component({
    selector: 'sc-subtitle-control',
    templateUrl: './subtitle-control.component.html',
    styleUrls: ['./subtitle-control.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true
})
export class SubtitleControlComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
