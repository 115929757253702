import {ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, Input, OnDestroy, OnInit} from '@angular/core';
import {CourseFacadeService} from '@core/facades/course.facade.service';
import {Subscription} from 'rxjs';
import {CourseTitleComponent} from './course-title/course-title.component';
import {CourseProgressComponent} from './course-progress/course-progress.component';
import {CourseProgressBarComponent} from './course-progress-bar/course-progress-bar.component';

@Component({
  selector: 'sc-course-progress-info',
  templateUrl: './course-progress-info.component.html',
  styleUrls: ['./course-progress-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CourseTitleComponent, CourseProgressComponent, CourseProgressBarComponent]
})
export class CourseProgressInfoComponent implements OnInit, OnDestroy {

  @Input() courseTitle: string;
  @Input() skipMode: boolean;

  courseCompletionTimer: { totalTime: number, completedTime: number };

  private _subscription: Subscription = new Subscription();
  private _courseFacade = inject(CourseFacadeService)
  private _cdr = inject(ChangeDetectorRef)

  ngOnInit(): void {
    this._subscription.add(
      this._courseFacade.courseCompletionTimer$.subscribe(v => {
        this.courseCompletionTimer = v;
        v.completedTime = Math.min(v.completedTime, v.totalTime);
        this._cdr.detectChanges();
      })
    )
  }

  ngOnDestroy() {
    this._subscription.unsubscribe();
  }

}
