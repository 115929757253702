import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { SliderModule } from 'primeng/slider';
import { FormsModule } from '@angular/forms';

@Component({
    selector: 'sc-volume-slider',
    templateUrl: './volume-slider.component.html',
    styleUrls: ['./volume-slider.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [SliderModule, FormsModule]
})
export class VolumeSliderComponent implements OnInit {

  @Input() volumeLevel: number;

  @Output() setVolume = new EventEmitter<number>();

  constructor() {
  }

  ngOnInit(): void {
  }

  changeVolume($event: any) {
    this.setVolume.emit($event.value)
  }

  onKeyUp(event: KeyboardEvent): void {
    if (event.keyCode === 107 || event.key === '=' || event.code === 'Equal') {
      this.volumeUp();
    }
    else if (event.keyCode === 109 || event.key === '-' || event.code === 'Minus') {
      this.volumeDown();
    }
  }

  volumeUp() {
    this.volumeLevel = this.volumeLevel >= 100 ? 100 : this.volumeLevel + 10;
    this.setVolume.emit(this.volumeLevel)
  }

  volumeDown() {
    this.volumeLevel = this.volumeLevel <= 0 ? 0 : this.volumeLevel - 10;
    this.setVolume.emit(this.volumeLevel)
  }
}
