
export type ScormAPI = {
  Initialize(val: string): boolean
  Terminate(val: string): boolean
  GetValue(element: string): string
  SetValue(element: string, value: string): string
  Commit(val: string): boolean
  GetLastError(): string
  GetErrorString(errorCode: string): string
  GetDiagnostic(errorCode: string): string

}


/**
 * root data model
 */
export enum CMI {
  _version = 'cmi._version',
  entry = 'cmi.entry',
  exit = 'cmi.exit',
  launch_data = 'cmi.launch_data',
  learner_id = 'cmi.learner_id',
  learner_name = 'cmi.learner_name',
  location = 'cmi.location',
  max_time_allowed = 'cmi.max_time_allowed',
  mode = 'cmi.mode',
  progress_measure = 'cmi.progress_measure',
  scaled_passing_score = 'cmi.scaled_passing_score',
  session_time = 'cmi.session_time',
  success_status = 'cmi.success_status',
  completion_status = 'cmi.completion_status',
  suspend_data = 'cmi.suspend_data',
  time_limit_action = 'cmi.time_limit_action',
  total_time = 'cmi.total_time'
}

export enum SuccessStatus {
  passed = 'passed',
  failed = 'failed',
  unknown = 'unknown'
}

export enum CompletionStatus {
  completed = 'completed',
  incomplete = 'incomplete',
  not_attempted = 'not attempted',
  unknown = 'unknown'
}

export enum EntryType {
  ab_initio = 'ab_initio',
  resume = 'resume'
}

export enum ExitType {
  timeout = 'timeout',
  suspend = 'suspend',
  logout = 'logout',
  normal = 'normal'
}

/**
 * construct data model
 */
export const cmi = CMI;


// v1.2 data models
/*export type CMIElement = {
  core : CmiCore,
  suspend_data: 'cmi.suspend_data'
}

export enum CmiCore {
  entry = "cmi.core.entry",
  student_name = "cmi.core.student_name",
  lesson_status = "cmi.core.lesson_status",
  lesson_location = "cmi.core.lesson_location"
}

export enum Status {
  Incomplete = 'incomplete',
  Completed = 'completed',
  Passed = 'passed',
  Failed = 'failed'
}

export enum Entry {
  Init = "ab-initio",
  Resume = 'resume'
}

export const cmi = {
  core: {
    entry: CmiCore.entry,
    student_name: CmiCore.student_name,
    lesson_status: CmiCore.lesson_status,
    lesson_location: CmiCore.lesson_location
  },
  suspend_data: 'cmi.suspend_data'
}*/
