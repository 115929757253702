import {Injectable} from '@angular/core';
import {CourseProgress} from '@models/course-progress.model';
import {localStorageKey} from '@models/course-progress-data-types.model';
import {of} from 'rxjs';

@Injectable()
export class CourseProgressDataService {


  constructor() {
  }

  saveDataInLocalStorage(progressData: CourseProgress): void {
    if(progressData) {
      const dataToSave = JSON.stringify(progressData);
      localStorage.setItem(localStorageKey, dataToSave)
    }
  }

  getDataFromLocalStorage() {
    const dataFromLocalStorage = localStorage.getItem(localStorageKey);
    const dataToReturn = dataFromLocalStorage ? JSON.parse(dataFromLocalStorage) : null;

    return of(dataToReturn);
  }
}
