import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TimelineFacadeService {

  isCountdown = false;
  countdownData$: Subject<{ time: number | null, duration: number | null }> = new Subject();

  initCountdownTimer(startFrom: number, countTo: number) {
    this.isCountdown = true;
    /*if(this._courseFacade.courseConfig.autoPlaySlides) {
      interval(1000).pipe(
        startWith(1),
        scan((acc, value) => value + 1),
        take(countTo + 1),
        // tap(timer => this.countdownData$.next({time: timer, duration: startFrom})),
        filter(timer => timer === countTo),
        // tap(time => console.log(time)),
      ).subscribe(time => {
        this.isCountdown = false;
        this._courseFacade.navigateToNextSlide();
      })
    }*/

  }

}
