import {
  Component,
  ChangeDetectionStrategy,
  InputSignal,
  input,
  output
} from '@angular/core';
import { transition, trigger } from '@angular/animations';
import { left, right } from '@core/animations';
import { SlideDataUnit, SlideProgress } from '@models/course-progress-data-types.model';
import { SlideCategory } from '@models/slide.model';
import { LayoutSlideComponent } from './layout-slide/layout-slide.component';
import {GroupSlideComponent} from "./group-slide/group-slide.component";
import {CheckboxSlideComponent} from "./checkbox-slide/checkbox-slide.component";
import {MediaSlideComponent} from "./media-slide/media-slide.component";
import {QuestionSlideComponent} from "./question-slide/question-slide.component";


@Component({
  selector: 'sc-slide-container',
  templateUrl: './slide-container.component.html',
  styleUrls: ['./slide-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('slide', [
      transition(':increment', right),
      transition(':decrement', left),
    ]),
  ],
  standalone: true,
  imports: [
    LayoutSlideComponent,
    GroupSlideComponent,
    CheckboxSlideComponent,
    MediaSlideComponent,
    QuestionSlideComponent
  ]
})
export class SlideContainerComponent {

   slideDataUnit: InputSignal<SlideDataUnit | null> = input.required();
   saveProgress = output<SlideProgress>()

  protected readonly SlideCategory = SlideCategory;
}
