import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import { NgIf } from '@angular/common';

@Component({
    selector: 'sc-player-icon',
    templateUrl: './player-icon.component.html',
    styleUrls: ['./player-icon.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [NgIf]
})
export class PlayerIconComponent implements OnInit {
  @Input() playingStatus: boolean | null;

  constructor() {
  }

  ngOnInit(): void {
  }

}
