<div class="question-section">
  <div class="question-icon">
    <svg width="24" height="24" viewBox="0 0 24 24">
      <g id="thunder_svg__Symbols" stroke="none" stroke-width="1" fill="#ffffff" fill-rule="evenodd">
        <g id="thunder_svg__Stockholm-icons-/-General-/-Thunder">
          <path
            d="M12.374 19.939l5.849-8.773A.75.75 0 0017.599 10H13V4.477a.75.75 0 00-1.374-.416l-5.849 8.773A.75.75 0 006.401 14H11v5.523a.75.75 0 001.374.416z"
            id="thunder_svg__Path-3"></path>
        </g>
      </g>
    </svg>
  </div>
  <div class="question-text">
    {{ question }}
    @if (questionTip) {
      <p class="multiple-answer-label">{{ questionTip }}</p>
    }
  </div>
</div>
