import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { PlayerIconComponent } from './player-icon/player-icon.component';
import { PlayerCurrentTimestampComponent } from './player-current-timestamp/player-current-timestamp.component';
import { PlayerTimelineComponent } from './player-timeline/player-timeline.component';
import { PlayerTimestampComponent } from './player-timestamp/player-timestamp.component';

@Component({
    selector: 'sc-video-controls',
    templateUrl: './video-controls.component.html',
    styleUrls: ['./video-controls.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [PlayerIconComponent, PlayerCurrentTimestampComponent, PlayerTimelineComponent, PlayerTimestampComponent]
})
export class VideoControlsComponent {

  @Input() currentTime: number;
  @Input() duration: number;
  @Input() unlockedTime: number;
  @Input() playingStatus: boolean;

  @Output() togglePlayEvent = new EventEmitter<boolean>();
  @Output() updateTime = new EventEmitter<number>();

  private _playing: boolean = true;

  constructor() {
  }

  togglePlayPause(status: string = 'playing') {
    if (status == 'pause') {
      this.togglePlayEvent.emit(false)
      return
    }
    this._playing = !this._playing
    this.togglePlayEvent.emit(this._playing)
  }
}
