import {Course} from './course.model';
import {CourseProgress} from './course-progress.model';
import {createSlideIdList, selectIdList} from '@root-store/course/course-store-utils';
import { Language } from '@models/language.model';

export enum ActiveSlideIndexLevel {
  Root,
  Tile
}

// export enum NavDirection {
//   Forward,
//   Backward
// }

/**
 * Course state
 * this contains all slide and course data for redux store
 */
export class CourseState {
  isCourseLoaded: boolean = false;
  isCourseLoading: boolean = false;
  showLanguageSelection = false;
  course?: Course
  images?: string[]
  courseProgress: CourseProgress;
  rootSlideIdList: string[];
  activeSlideIdList: string [];
  activeSlideIndexLevel: ActiveSlideIndexLevel = ActiveSlideIndexLevel.Root;
  courseLanguages: Language[] | null = null;

  constructor(course?: Course, courseProgress?: CourseProgress | null, languageCode?: string, asi?:string, isUnlocked?: boolean) {
    if (course) {
      this.course = course;
      this.rootSlideIdList = selectIdList(course.slides);
      this.activeSlideIdList = this.rootSlideIdList;
      this.isCourseLoading = false;
      this.isCourseLoaded = true;

      if (courseProgress) {
        if (languageCode) {
          this.courseProgress = {
            ...courseProgress,
            languageCode: languageCode
          };
        }

      } else {
        const fullIdList = createSlideIdList(course.slides);
        this.courseProgress = new CourseProgress(
          course,
          fullIdList,
          languageCode as string,
          asi as string,
          isUnlocked
        );
      }
    }
  }

}
