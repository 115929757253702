import {ChangeDetectionStrategy, Component, input, InputSignal} from '@angular/core';
import {PointerSlide} from "@models/slide.model";
import {DialogModule} from "primeng/dialog";

@Component({
  selector: 'sg-pointer-icon',
  templateUrl: './pointer-icon.component.html',
  standalone: true,
  styleUrls: ['./pointer-icon.component.scss'],
  imports: [
    DialogModule,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PointerIconComponent {
  pointer: InputSignal<PointerSlide> = input.required();
  isCompleted: InputSignal<boolean> = input.required();
}
