<section class="hotspot-container" #container>
  <div class="hotspot-pointers">

    @for (pointer of pointers(); track pointer; let index = $index) {
      <sg-pointer-icon [pTooltip]="pointer?.title"
                       (click)="setSelectedPoint(index,pointer, $any(contentDataUnits[index].slideProgress).err)"
                       [pointer]="pointer"
                       [isCompleted]="contentDataUnits[index].slideProgress.slideCompleted"
                       (mousedown)="this.selectedPointerIndex = index"
                       [class.active]="this.selectedPointerIndex === index"
                       [style.top.%]="pointer.top"
                       [style.left.%]="pointer.left"/>
    }
  </div>
  <div class="image-container">
    @if (hotspotSlide().graphic; as graphic) {
      @if (graphic.fileType === FileType.VIDEO) {
        <video width="100%"
               autoplay
               loop
               [src]="graphic.fileUrl"
               playsinline>
        </video>
      } @else if (graphic.fileType == FileType.LOTTIE) {
        <ng-lottie [options]="{path: graphic.fileUrl}"
                   width="100%" height="100%"/>
      } @else {
        <div class="image"
             [style.background-size]="hotspotSlide().layoutType == ImageSize.FILL? 'cover' : 'contain'"
             [style.background-image]="'url(' + graphic?.fileUrl + ')'">
        </div>
      }
    }
  </div>
</section>

@if(selectedPointer){
  <p-dialog [maximizable]="true"
            [draggable]="false"
            [modal]="true"
            [resizable]="false"
            [dismissableMask]="true"
            [(visible)]="visible"
            header="{{selectedPointer.title}}"
            [style]="{width: '30vw'}">
    <p [innerHTML]="selectedPointer.popupText.html"></p>
    @if (!selectedPointer.title && !selectedPointer.popupText) {
      <p class="text-center">No content available</p>
    }
  </p-dialog>
}



