import { ChangeDetectionStrategy, Component, input, Input, InputSignal } from '@angular/core';
import {LottieComponent} from "ngx-lottie";
import {Answer} from "@models/slide.model";

@Component({
  selector: 'sg-answer-section',
  templateUrl: './answer-section.component.html',
  standalone: true,
  styleUrls: ['./answer-section.component.scss'],
  imports: [
    LottieComponent
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AnswerSectionComponent {

  @Input() answer: Answer;
  @Input() index: number;
  correctAnswerIndexes: InputSignal<number[]> = input.required({
    transform: (correctAnswerIndexes) => {
      this.answerStatus = correctAnswerIndexes.includes(this.index);
      return correctAnswerIndexes

    }
  })

  @Input() set savedAnswerIndexes(savedAnswerIndexes: number[]) {
    this.answered = savedAnswerIndexes.includes(this.index);
  };

  answerStatus: boolean;
  answered: boolean;

}
