import { inject, Injectable } from '@angular/core';
import { DialogService } from 'primeng/dynamicdialog';
import {
  InformationDialogData,
} from '@models/popup.model';
import { InformationDialogComponent } from './information-dialog/information-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class DynamicDialogService {
  private _dialogService: DialogService = inject(DialogService);


  openInformationDialog(data: InformationDialogData) {
    this._dialogService.open(InformationDialogComponent, {
      showHeader: false,
      dismissableMask: true,
      width: '300px',
      styleClass: 'content-no-padding',
      data: data
    });
  }

}
