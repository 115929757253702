import { ChangeDetectionStrategy, Component, inject, Input, OnInit } from '@angular/core';
import { CourseFacadeService } from '@core/facades/course.facade.service';
import { Observable } from 'rxjs';
import { SlideCompletionFormat } from '@models/course.model';
import { NgIf, AsyncPipe } from '@angular/common';
import { TimeRemainingPipePipe } from './time-remaining.pipe';
import { PercentagePipe } from './percentage.pipe';

@Component({
    selector: 'sc-course-progress',
    templateUrl: './course-progress.component.html',
    styleUrls: ['./course-progress.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [NgIf, AsyncPipe, TimeRemainingPipePipe, PercentagePipe]
})
export class CourseProgressComponent implements OnInit {

  @Input() courseCompletionTimer: { totalTime: number, completedTime: number };

  totalTime: string;
  currentTime: string;
  selectedLanguage$: Observable<string>;
  slideCompletionFormat = SlideCompletionFormat;
  private _courseFacadeService = inject(CourseFacadeService)
  slideCompletionFormatConfig = this._courseFacadeService.courseConfig.slideCompletionFormat;

  constructor(private _courseFacade: CourseFacadeService) {
  }

  ngOnInit(): void {
    this.selectedLanguage$ = this._courseFacade.selectedLanguage$;
  }

}
