import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  standalone: true,
  name: 'answerPrompt'
})

export class AnswerPromptPipe implements PipeTransform {

  translations: {
    [key: string]: {
      text: string;
    }
  } = {
    'sv': {
      'text': 'Ogiltigt svar, försök igen!',
    },
    'en': {
      'text': 'Invalid Answer, Try again!',
    },
    'ja': {
      'text': '無効な回答です。もう一度お試しください。',
    },
    'fr': {
      'text': 'Réponse invalide, réessayez !',
    },
    'pt-br': {
      'text': 'Resposta inválida, tente novamente!',
    },
    'ko': {
      'text': '잘못된 답변입니다. 다시 시도하세요!',
    },
    'de': {
      'text': 'Ungültige Antwort, versuchen Sie es erneut!',
    },
    'cs': {
      'text': 'Neplatná odpověď, zkuste to znovu!',
    },
    'zh-tw': {
      'text': '答案無效，請重試',
    },
    'es': {
      'text': 'Respuesta inválida, inténtalo de nuevo!',
    },
    'zh': {
      'text': '答案无效，请重试',
    },
    'nl': {
      'text': 'Ongeldig antwoord, probeer het opnieuw!',
    },
    'ru': {
      'text': 'Неверный ответ. Попробуйте еще раз!',
    },
    'it': {
      'text': 'Risposta non valida, riprova!',
    }
  }

  transform(languageCode: string | null): string {
    if (languageCode) {
      return `${this.translations[languageCode].text}`;
    }
    return 'Invalid Answer, Try again!'
  }

}
