import {Injectable, signal, WritableSignal} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {GroupCategory, GroupSlide, Slide} from '@models/slide.model';

@Injectable({
  providedIn: 'root'
})
export class NavigationFacadeService {

  skipMode: WritableSignal<boolean> = signal(false);
  canGoBack$ = new BehaviorSubject(false);

  private _slideForwardPointer: Generator<{ pointer: number, slide: Slide } | undefined>;
  private _slideBackwardPointer: Generator<{ pointer: number, slide: Slide } | undefined>;
  private _slideNavList: Slide[] = [];
  private _currentSlidePositionIndex = 0;
  private _navInitialized = false;

  getNextSlide(slideId?: string): Slide {

    if (!slideId) {
      const currentSlide = this._slideNavList[
        this._currentSlidePositionIndex === -1 ? 0 : this._currentSlidePositionIndex
        ];
      const nextSlide = this._slideNavList[this._currentSlidePositionIndex + 1];
      const currentSlideDeep = currentSlide.link.length;

      if (currentSlideDeep === 1) {
        const rootSlides = this._slideNavList.filter(slide => slide.link?.length === 1);
        const currentRootIndex = rootSlides.findIndex(slide => slide.id === currentSlide.id);
        const nextRootSlide = rootSlides[currentRootIndex + 1];
        this.setForwardPointer(nextRootSlide.index)
        return nextRootSlide;
      } else if ((currentSlideDeep % 2) === 1) {
        return this.setSubForwardPointer(currentSlide)
      }

      this.setForwardPointer(nextSlide.index, true)
      return nextSlide;
    }

    // Navigate to next slide if user click on pointer or tile
    const selectedSlideIndex = this._slideNavList.findIndex((slide: Slide) => slide.id === slideId);

    this._slideForwardPointer = slidePointerGenerator(
      this._slideNavList,
      selectedSlideIndex,
      true
    );

    // skip tile and select next child content
    const {pointer, slide} = this._slideForwardPointer.next().value;
    this._currentSlidePositionIndex = pointer;
    this._updateNavBackCondition();
    return slide;
  }

  setSubForwardPointer(currentSlide: Slide): Slide {
    if (currentSlide.lastItemInLink) {
      const {link} = currentSlide;
      const nextSlide = this._slideNavList.find(slide => slide.id === link[link.length - 3]);
      this.setForwardPointer(nextSlide!.index)
      return nextSlide!;
    }
    const children = this._slideNavList.filter(slide => {
      return slide.link.length === currentSlide.link.length;
    });

    const currentChildIndex = children.findIndex(slide => slide.id === currentSlide.id);
    const nextChildSlide = children[currentChildIndex + 1];
    this.setForwardPointer(nextChildSlide.index)
    return nextChildSlide;
  }

  setForwardPointer(nextSlideIndex: number, skipForwardPointer: boolean = false) {
    if (skipForwardPointer) {
      this._slideForwardPointer = slidePointerGenerator(
        this._slideNavList,
        nextSlideIndex,
        true
      );
    }
    this._currentSlidePositionIndex = nextSlideIndex;
    this._updateNavBackCondition();
  }

  getPreviousSlide(): Slide {

    const {pointer, slide} = this._slideBackwardPointer.next().value;

    if ((slide as GroupSlide).subCategory && (slide.subCategory === GroupCategory.TILE || slide.subCategory === GroupCategory.POINTER)) {
      return this.getPreviousSlide();
    }

    let previousSlide;
    let currentSlide = this._slideNavList[this._currentSlidePositionIndex];
    const flatIds = currentSlide.link.flat(1)
    const currentSlideDeep = flatIds.length;

    if (currentSlideDeep === 1) {

      const rootSlidesList = this._getRootSlidesList(this._slideNavList);
      const newIndex = rootSlidesList.findIndex(item => item.id === currentSlide.id);
      previousSlide = rootSlidesList[newIndex - 1];

      this._currentSlidePositionIndex = previousSlide.index;

      this._slideForwardPointer = slidePointerGenerator(
        this._slideNavList,
        this._currentSlidePositionIndex,
        true
      );

      this._updateNavBackCondition();
      return previousSlide;

    } else if ((currentSlideDeep % 2) === 1) {

      const children = this._slideNavList.filter(item => {
        return item.link[currentSlideDeep - 2] === flatIds[currentSlideDeep - 2] && item.link.length == flatIds.length;
      });

      const currentChildIndex = children.findIndex(item => item.id === currentSlide.id);

      if (currentChildIndex === 0) {
        previousSlide = this._slideNavList.find(item => item.id === flatIds[flatIds.length - 3]) as Slide
      } else {
        previousSlide = children[currentChildIndex - 1];
      }

      this._currentSlidePositionIndex = previousSlide.index;
      this._slideForwardPointer = slidePointerGenerator(
        this._slideNavList,
        this._currentSlidePositionIndex,
        true
      );

      this._updateNavBackCondition();
      return previousSlide;
    }

    this._currentSlidePositionIndex = pointer;

    this._slideForwardPointer = slidePointerGenerator(
      this._slideNavList,
      this._currentSlidePositionIndex,
      true
    );

    this._updateNavBackCondition();
    return slide;
  }

  initNavigation(slideNavList: Slide[]) {
    if (!this._navInitialized) {
      this._slideNavList = slideNavList/*.filter(slide => (
        !(slide.type === CourseSlideType.TILE || slide.type === CourseSlideType.POINTER)
      ))*/;
      this._processPointerGenerators();
      this._updateNavBackCondition();
      this._navInitialized = true;
    }
  }

  setCurrentPointerIndex(index: number) {
    this._currentSlidePositionIndex = index;
  }

  private _updateNavBackCondition() {
    this._slideBackwardPointer = slidePointerGenerator(
      this._slideNavList,
      this._currentSlidePositionIndex,
      false
    );
    this.canGoBack$.next(this._currentSlidePositionIndex > 0)
  }

  private _processPointerGenerators() {

    this._slideForwardPointer = slidePointerGenerator(
      this._slideNavList,
      this._currentSlidePositionIndex
    );
    this._slideBackwardPointer = slidePointerGenerator(
      this._slideNavList,
      this._currentSlidePositionIndex,
      false
    );
  }

  private _getRootSlidesList(navList: Slide[]): Slide[] {
    return [...new Map(
      navList.map(slide => {
        return [slide.id, slide]
      })
    ).values()].filter(slide => {
      if (slide.link) {
        return (slide?.link && slide?.link.length === 1)
      }
      return true
    });
  }
}

function* slidePointerGenerator(
  slideList: Slide[],
  pointer = 0,
  forward: boolean = true
): Generator<{ pointer: number, slide: Slide } | undefined> {

  while (true) {
    forward ? pointer++ : pointer--;
    const slide = slideList[pointer]
    yield {pointer, slide};
  }

}
