import {} from '@models/course.model';
import {
  GroupProgress,
  MediaProgress,
  SlideProgress,
  SlideProgressData,
} from '@models/course-progress-data-types.model';
import { SlideCategory, Slides } from '@models/slide.model';

/**
 * Convert Object to an array
 * @param obj
 * T[]
 */
export function convertObjectToArray<T = object>(obj: { [key: string]: T }): T[] | null {
  return typeof obj === 'object' ? Object.keys(obj).map(key => obj[key]) : null;
}


/**
 * get all ids as an array or 2d/3d array to
 * generate slide progress data
 * @param slides
 * > (string | (string | string[])[])[]
 */
export function createSlideIdList(slides: Slides): (string | (string | string[])[])[] {
  return Object
    .keys(slides)
    .map(slideId => slides[slideId])
    .sort((a, b) => a.index - b.index)
    .map(slide => {
      // if(slide.category === SlideCategory.LAYOUT) {
      //   return SlideCategory.TITLE
      // }
      if (slide.category === SlideCategory.GROUP) {
        const {content, id} = slide ;
        const tileIds: (string | (string | string[])[])[] = [];
        /*Object.keys(content).forEach(tileId => {
          const {content} = content[tileId];
          const tileSlideContentIds: string[] = [];

          content && Object.keys(content).forEach(tileContentKey => {
            tileSlideContentIds.push(tileContentKey);
          })

          tileIds.push([tileId, [...tileSlideContentIds]]);
        });*/
        return [id, [...tileIds]]
      }
      return slide.id;
    })
    .filter(slideId => !!slideId) as string[];
}

/**
 * get id list as string[]
 * @param slides
 * @param isTile
 */
export function selectIdList(slides: Slides | GroupProgress | SlideProgressData, isTile?: boolean): string[] {
  return isTile ?
    Object.keys((slides as GroupProgress).cRef).map(key => key) :
    Object.keys(slides as Slides)
      .filter(function (key) {
        return key !== 'end'
      })
      .sort((a, b) => (slides as Slides)[a].index - (slides as Slides)[b].index)
      .map(key => key)
}

/**
 * Calculate completed time
 * @param slide
 * @param competedTime
 */
let lastUpdatedValue = 0;

export function calculateCompletedTime(slide: SlideProgress, competedTime: number): number {
  let time = competedTime;

  switch (slide.category) {
    case SlideCategory.QUESTION:
      if (slide.isSlideUnlocked && slide.slideCompleted) {
        time += slide.duration;
      }
      break;

    /*case CourseSlideType.HOTSPOT:
      if (slide.isSlideUnlocked && slide.slideCompleted) {
        time += slide.duration;
      }
      break;*/

    case SlideCategory.MEDIA:
      if (!slide.isSlideUnlocked) {
        const currentTime = (slide as MediaProgress).currentTime;
        time += (currentTime - lastUpdatedValue);
        lastUpdatedValue = currentTime
      } else {
        lastUpdatedValue = 0;
      }
      break;

    /*case CourseSlideType.EXPLAINER:
      if (!slide.isSlideUnlocked) {
        time += ((slide as ExplainerProgress).currentTime);
      }
      break;*/

    case SlideCategory.CHECKBOX:
      if (slide.isSlideUnlocked && slide.slideCompleted) {
        time += slide.duration;
      }
      break;

    /*case CourseSlideType.IMAGE:
      if (slide.isSlideUnlocked && slide.slideCompleted) {
        time += slide.duration;
      }
      break;*/

    case SlideCategory.LAYOUT:
      if (slide.isSlideUnlocked && slide.slideCompleted) {
        time += slide.duration;
      }
      break;

    /*case CourseSlideType.BACKGROUND_VIDEO:
      if (slide.isSlideUnlocked && slide.slideCompleted) {
        time += slide.duration;
      }
      break;*/
  }

  return time

}

/**
 * Preload Images for Slides
 * @param course
 */
/*
export function preloadImagesForSlide(course: Course) {
  let paths: any[] = [];
  Object.entries(course.slides).forEach(function (value, index) {
    if (value[1].type === 'Image') {
      paths.push(value[1].image);
    }
  });
  return paths;
}
*/
