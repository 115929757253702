<div class="language-container" *ngIf="languages$ | async as languages">
  <div class="language-section-wrapper" (window:keydown)="navigate(languages, $event)">
    <div class="choose-language-title">
      <sc-language-title></sc-language-title>
    </div>
    <div class="language-item-wrapper">
      <sc-language-link-item
        *ngFor='let language of languages; let i = index'
        (click)="setLanguage(language.code)"
        [ngClass]="selectedLanguageIndex == i ? 'selected-language' :''"
        [languageText]="language.name">
      </sc-language-link-item>
    </div>
  </div>
</div>

