import { Component, ChangeDetectionStrategy } from '@angular/core';
import { Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import { TimelineFacadeService } from '@core/facades/timeline.facade.service';
import { AsyncPipe } from '@angular/common';

@Component({
    selector: 'sc-video-timer',
    templateUrl: './video-timer.component.html',
    styleUrls: ['./video-timer.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [AsyncPipe]
})
export class VideoTimerComponent {

  countDownTimer$: Observable<string>;
  timerPlaceholder: string = '--:--';

  constructor(private _timelineFacade: TimelineFacadeService) {
    this.countDownTimer$ = _timelineFacade.countdownData$.pipe(
      map(({time, duration}) => duration && time ? duration - time: null),
      map(sec => this._convertSecondToRemainingTime(sec ? sec : null))
    )
  }

  /**
   * Convert duration to remaining time string
   * @param duration
   * @private
   */
  private _convertSecondToRemainingTime(duration: number | null): string {
    if (!duration){
      return '--:--';
    }
    const _seconds = Math.trunc(duration);

    const minutes = Math.floor((_seconds / 60));
    const seconds = _seconds - (minutes * 60);

    const minStr = String(minutes).padStart(2, '0');
    const secStr = String(seconds).padStart(2, '0');

    return `${minStr}:${secStr}`
  }

}
