import {
  GroupCategory,
  GroupSlide,
  LayoutCategory,
  MediaCategory,
  MediaSlide, PointerSlide, PointerType,
  Slide,
  SlideCategory,
  Slides,
  SlideType,
  TileSlide
} from '@models/slide.model';

/**
 * common interfaces and types for course progress
 */
export interface SlideProgressData {
  [slideIndex: string]: SlideProgress;
}

export type SlideProgress =
  | QuestionProgress
  | CheckboxProgress
  | GroupProgress
  | MediaProgress
  | LayoutProgress

export interface TimerConfig {
  run: boolean;
  duration: number;
  from: number;
}

export const localStorageKey = 'sg_course_progress'


/**
 * data types to save in scorm or other LMS api
 */

class SlideProgressBase {
  isSlideUnlocked: boolean = false;
  category: SlideCategory;
  id: string;
  duration: number = 30;
  slideCompleted = false;
  parentId?: string;

  constructor(slide: Slide) {
    this.category = slide.category;
    this.id = slide.id;
  }
}


/**
 * Question Progress Data
 */
export class QuestionProgress extends SlideProgressBase {
  allowedAttempts?: number;
  attempts: number
  isSelectedAnswerCorrect: boolean = false;
  selectedAnswerIndexes: number[] = [];

  constructor(slide: Slide) {
    super(slide)
  }
}


/**
 * Checkbox Progress Data
 */
export class CheckboxProgress extends SlideProgressBase {
  checked: boolean = false;

  constructor(slide: Slide) {
    super(slide)
  }
}

/**
 * Level Progress Data
 */
export class GroupProgress extends SlideProgressBase {

  subCategory: GroupCategory;
  isLock: boolean;
  cRef: string[] = []; // content refs (associated tile id list)
  err = false;

  override duration = 0

  constructor(slide: GroupSlide) {
    super(slide);

    if (slide.content === null || (slide.content && Object.keys(slide.content).length === 0)) {
      // Run this block if slide.content is null or has 0 keys

      if (slide.subCategory === GroupCategory.TILE ||
        (slide.subCategory === GroupCategory.POINTER && (slide as PointerSlide).pointerType === PointerType.NESTED)
      ) {
        this.isSlideUnlocked = true;
        this.slideCompleted = true;
        this.err = true;
      }
    } else {
      this.cRef = Object.keys(slide.content);
    }

    if (slide.subCategory === GroupCategory.LEVEL) {
      const hasNonNullContent = Object
        .values(slide.content)
        .some(
          item => (item as GroupSlide).content !== null && Object.keys((item as GroupSlide).content).length > 0
        );

      if (!hasNonNullContent) {
        this.isSlideUnlocked = true;
        this.slideCompleted = true;
        this.err = true
      }
    }

    if (slide.subCategory == GroupCategory.TILE) {
      this.isLock = (slide as TileSlide).isTileLocked
    }
  }

}


export class MediaProgress extends SlideProgressBase {
  currentTime: number = 0;
  playing: boolean = false;

  constructor(mediaSlide: MediaSlide) {
    super(mediaSlide);
    if (mediaSlide.data) {

      if (!mediaSlide.data.isProcessed) {
        this.isSlideUnlocked = true;
        this.slideCompleted = true;
      } else {
        this.duration = mediaSlide.data?.misc!.duration!;
      }

    } else {
      this.duration = 0;
      // this.isSlideUnlocked = true;
      this.slideCompleted = true;
    }

  }
}


/**
 * Layout Progress Data
 */
export class LayoutProgress extends SlideProgressBase {
  checked: boolean = false;
  override isSlideUnlocked = true;

  constructor(slide: Slide) {
    super(slide);
    if (slide.subCategory == LayoutCategory.TITLE) {
      this.duration = 0;
    }
  }
}


/**
 * Slide unit with combined progress data and slide content
 */
export class SlideDataUnit<T extends SlideType = SlideType> {
  category: SlideCategory;
  subCategory: GroupCategory | MediaCategory | LayoutCategory | undefined;
  slideProgress: SlideProgress;
  slide: T

  constructor(slide: T, slideProgress: SlideProgress) {
    this.slide = slide;
    this.slideProgress = slideProgress ? JSON.parse(JSON.stringify(slideProgress)) : slideProgress
    this.category = slide.category
    this.subCategory = slide.subCategory
  }
}

/**
 * factory class to create class types
 */
export class ProgressDataFactory {

  level: number = 1;

  static createSlideProgressData(slides: Slides, isUnlocked: boolean): SlideProgressData {

    const slideProgressData: SlideProgressData = {};

    Object.values(slides || {}).forEach((rootSlide) => {

      slideProgressData[rootSlide.id] = ProgressDataFactory
        ._createProgressDataType(
          rootSlide,
          isUnlocked
        ) as GroupProgress;

    });

    return slideProgressData;
  }

  private static _createProgressDataType(slide: Slide, isUnlocked?: boolean): SlideProgressBase | null {

    let slideProgressData: SlideProgressBase;

    switch (slide.category) {
      case SlideCategory.LAYOUT:
        slideProgressData = new LayoutProgress(slide);
        break;

      case SlideCategory.QUESTION:
        slideProgressData = new QuestionProgress(slide);
        break;

      case SlideCategory.GROUP:
        slideProgressData = new GroupProgress(slide as GroupSlide);
        break;

      case SlideCategory.MEDIA:
        slideProgressData = new MediaProgress(slide as MediaSlide);
        break;

      case SlideCategory.CHECKBOX:
        slideProgressData = new CheckboxProgress(slide);
        break;

      default:
        return null
    }

    if (isUnlocked) {
      slideProgressData.isSlideUnlocked = true;
    }

    return slideProgressData;

  }

  static createSlideDataUnit(activeSlideId: string, slides: Slides, progressData: SlideProgressData): any {
    return new SlideDataUnit(slides[activeSlideId], progressData[activeSlideId]);
  }

  static createGroupChildDataUnitList(childIdList: string[], slides: Slides, progressData: SlideProgressData): any {
    return childIdList
      .filter(childId => !!slides[childId])
      .map(childId => {
        return new SlideDataUnit(slides[childId], progressData[childId]);
      })
  }
}
