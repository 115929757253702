import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import {LottieComponent} from "ngx-lottie";

@Component({
  selector: 'sg-events',
  templateUrl: './events.component.html',
  standalone: true,
  styleUrls: ['./events.component.scss'],
  imports: [
    LottieComponent
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EventsComponent implements OnInit {

  @Input() lottieAnimation: string;

  constructor() { }

  ngOnInit(): void {
  }

}
