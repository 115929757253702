import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import { ConvertSecondsToMinuteStringPipe } from '../../../../../@core/pipes/convert-seconds-to-minute-string.pipe';

@Component({
    selector: 'sc-player-timestamp',
    templateUrl: './player-timestamp.component.html',
    styleUrls: ['./player-timestamp.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [ConvertSecondsToMinuteStringPipe]
})
export class PlayerTimestampComponent implements OnInit {

  @Input() duration: number;

  constructor() {
  }

  ngOnInit(): void {
  }

}
