<div class="container">
  <div class="header">
    <i class="icon-info"></i>
    <span class="header-text">{{ dialogData?.header }}</span>
  </div>
  <div class="body-text">
    <p>{{ dialogData?.bodyText }}</p>
  </div>
  <button (click)="closeDialog()" class="btn btn-primary">{{ dialogData?.buttonText || 'Close' }}</button>
</div>




