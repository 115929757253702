import {ChangeDetectionStrategy, Component} from '@angular/core';
import {
  getLanguages,
  selectLanguage
} from '@root-store/course/course.actions';
import {Store} from '@ngrx/store';
import {Observable, of} from 'rxjs';
import {Language} from '@models/language.model';
import {selectLanguages} from '@root-store/course/course.selectors';
import { NgIf, NgFor, NgClass, AsyncPipe } from '@angular/common';
import { LanguageTitleComponent } from './language-title/language-title.component';
import { LanguageLinkItemComponent } from './language-link-item/language-link-item.component';

@Component({
    selector: 'sc-language',
    templateUrl: './language.component.html',
    styleUrls: ['./language.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [NgIf, LanguageTitleComponent, NgFor, LanguageLinkItemComponent, NgClass, AsyncPipe]
})
export class LanguageComponent {

  languages$: Observable<Language[] | null> = of(null);
  selectedLanguageIndex: number = -1;

  constructor(private _store: Store) {
    this._store.dispatch(getLanguages());
    this.languages$ = this._store.select(selectLanguages);
  }


  setLanguage(languageCode: string) {
    this._store.dispatch(selectLanguage({languageCode}))
  }


  navigate(languages: Language[], event: any) {
    const languageCount = languages.length;
    switch (event.code) {
      case 'ArrowDown':
      case 'Tab':
        this.selectedLanguageIndex = (this.selectedLanguageIndex + 1) % languageCount;
        break;
      case 'ArrowUp':
        this.selectedLanguageIndex = (this.selectedLanguageIndex - 1 + languageCount) % languageCount;
        break;
      case 'Enter':
      case 'Space':
        this.setLanguage(languages[this.selectedLanguageIndex].code);
        break;
    }
  }
}
