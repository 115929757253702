import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import { NgIf } from '@angular/common';

@Component({
    selector: 'sc-fullscreen-control',
    templateUrl: './fullscreen-control.component.html',
    styleUrls: ['./fullscreen-control.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [NgIf]
})
export class FullscreenControlComponent implements OnInit {

  @Input() isFullscreen: boolean;
  constructor() { }

  ngOnInit(): void {
  }

}
