import {ChangeDetectionStrategy, Component, Input} from '@angular/core';

@Component({
  selector: 'sg-question-section',
  templateUrl: './question-section.component.html',
  standalone:true,
  styleUrls: ['./question-section.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class QuestionSectionComponent {

  @Input() question: string | undefined;
  @Input() questionTip: string | undefined;

}
