@if (mediaSlide().data; as mediaData) {
  @if (mediaData.isProcessed && mediaData.fileUrl) {
    @if(mediaData.label === 'Exporting') {
      <div class="video-info">
        <span class="info-label">Your video is being processed. It will update automatically once the export is complete.</span>
      </div>
    }@else {
      <sc-video-player
        [videoSource]="{id:mediaSlide().id,duration: mediaData.duration, sources: [{src: mediaData.fileUrl,type: 'video/mp4'}], subtitleUrl:mediaData?.misc?.subtitleUrl || ''}"
        [volume]="volume$ | async"
        [unlockedTime]="mediaProgress().currentTime"
        [subtitle]="subtitle()"
        (currentTimeChange)="onVideoTimeUpdate($event)"
        (videoEnd)="onVideoEnd()"
        (playing)="onVideoPlay($event)"/>
    }

  } @else {
    <div class="media-not-exported"
         [style.background-image]="'url('+ mediaData.thumbUrl + ')'">
      <div class="player-details">
        <span>Please export the {{ mediaSlide().subCategory }} in order to preview it correctly.</span>
      </div>
    </div>
  }
} @else {
  <div class="video-info">
    <span class="info-label">{{ mediaSlide().subCategory }} has not been selected</span>
  </div>
}
