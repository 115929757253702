<sg-layout-template [layoutType]="layoutSlide().layoutType"
                    [graphic]="layoutSlide().graphic"
                    [logoImage]="layoutSlide().logo">

  @if (layoutSlide().slideText; as slideText) {
    <div class="html" [innerHTML]="slideText.html"></div>
  }

</sg-layout-template>

@if (layoutSlide().textToSpeech.enableTTS) {
  <sc-audio-narrator [selectedLanguage]="selectedLanguage$ | async"
                     [volume]="volume$ | async"
                     [audioUrl]="layoutSlide().textToSpeech.ttsAudioUrl"/>
}


