import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'sg-base',
  templateUrl: './base.component.html',
  standalone: true,
  styleUrls: ['./base.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BaseComponent {

  @Input() url: string;
  @Input() tileIndex: string;

}
