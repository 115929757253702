<section class="layout-container" [ngClass]="layoutType()">
  <div class="graphic" [class.no-bg]="!!graphic()">
    @if (graphic()) {
      <sg-graphic-section [graphic]="graphic()!"/>
    }
  </div>

  <div class="content">
    <ng-content/>
  </div>
</section>
