import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';

@Component({
    selector: 'sc-language-title',
    templateUrl: './language-title.component.html',
    styleUrls: ['./language-title.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true
})
export class LanguageTitleComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
