import { enableProdMode, importProvidersFrom } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';


import { environment } from '@env/environment';
import { provideLottieOptions } from 'ngx-lottie';
import { BrowserModule, bootstrapApplication } from '@angular/platform-browser';
import { CoreModule } from '@core/core.module';
import { RootStoreModule } from '@root-store/root-store.module';
import { provideAnimations } from '@angular/platform-browser/animations';
import { LayoutModule } from '@core/layout/layout.module';
import { provideRouter } from '@angular/router';
import { AppComponent } from './app/app.component';

if (environment.production) {
  enableProdMode();
}

bootstrapApplication(AppComponent, {
    providers: [
        importProvidersFrom(BrowserModule, CoreModule, RootStoreModule, LayoutModule),
        provideLottieOptions({
            player: () => import('lottie-web'),
        }),
        provideAnimations(),
        provideRouter([])
    ]
})
  .catch(err => console.error(err));
