import {
  Component,
  ChangeDetectionStrategy,
  Input,
  ViewChild,
  ElementRef,
  OnDestroy,
  Output, EventEmitter, InputSignal, input
} from '@angular/core';
import {PlayerEvents, PlayerOptions, VideoData} from '@models/videojs.model';
import videojs from 'video.js';
import {Music} from '@models/music.model';
import {Observable, Subject,} from 'rxjs';
import {TimelineFacadeService} from '@core/facades/timeline.facade.service';
import {distinctUntilChanged, tap} from 'rxjs/operators';
import Player from 'video.js/dist/types/player';
import {VideoControlsComponent} from './video-controls/video-controls.component';
import {NgIf, AsyncPipe} from '@angular/common';


@Component({
  selector: 'sc-video-player',
  templateUrl: './video-player.component.html',
  styleUrls: ['./video-player.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [VideoControlsComponent, NgIf, AsyncPipe]
})

export class VideoPlayerComponent implements OnDestroy {

  @Input() options: PlayerOptions;
  @Input() autoplay: boolean = true;
  @Input() unlockedTime: number = 100;

  @Input() subtitleUrl(url: string) {
    if (url) {
      this._updateSubtitleSource(url)
    }
  };

  @Input() set videoSource(videoData: VideoData) {
    this.duration = 0;
    this.videoData = videoData;
    this._initPlayer(videoData);
  }

  @Input() set videoNarratorSource(narratorSource: string) {
    if (narratorSource) {
      this._narrationAudioPlayer = new Audio(narratorSource);
      this._narrationAudioPlayer.load();
    }
  }

  @Input() set videoMusicSource(music: Music) {
    if (music) {
      this._track = music;
      this._musicAudioPlayer = new Audio(music.url);
      this._musicAudioPlayer.volume = music.volume;
      this._musicAudioPlayer.loop = true;
      this._musicAudioPlayer.load();
    }
  }

  @Input() set playFrom(time: number) {
    this._playFrom = time;
  }

  @Input() set volume(volumeLevel: number | null) {
    if (volumeLevel !== null) {
      this._changeVolume(volumeLevel)
    }
  }

  subtitle: InputSignal<boolean> = input.required({
    transform: (subtitle: boolean) => {
      this._toggleSubtitle = subtitle;
      this._showSubtitle()
      return subtitle
    }
  });

  @Output() videoDuration = new EventEmitter<number>();
  @Output() currentTimeChange = new EventEmitter<number>();
  @Output() videoEnd = new EventEmitter();
  @Output() playing: EventEmitter<{ currentTime: number, playing: boolean }> = new EventEmitter();

  @ViewChild('videoElementRef', {static: true}) videoElementRef: ElementRef;

  player!: Player;
  videoData: VideoData;
  // loadedTime: number = 0;
  duration: number;
  // currentTime: number;
  timeUpdate$: Observable<number>;
  currentTime: number;
  isPlaying: boolean = false;

  private _toggleSubtitle: boolean | null;
  private _narrationAudioPlayer: HTMLAudioElement;
  private _musicAudioPlayer: HTMLAudioElement;
  private _track: Music;
  private _isPlayerInited = false;
  private _events: string[] = Object.keys(PlayerEvents);
  private _waiting = false;
  private _seeking = false;
  private _playFrom: number;
  private _currentVideoTimeStream$: Subject<number> = new Subject<number>();


  constructor(private _timelineFacade: TimelineFacadeService) {

    this.timeUpdate$ = this._currentVideoTimeStream$.pipe(
      distinctUntilChanged(),
      tap(time => {
        this.currentTimeChange.emit(time);
        if (this.player.duration() != this.videoData.duration) {
          console.log("Warning : Video time different detected ", this.player.duration(), this.videoData.duration)
        }
      }),
      tap(time => _timelineFacade.countdownData$.next({
          time: time,
          duration: Math.trunc(this.player.duration() as number)
        })
      ),
    )
  }

  hide() {
    return false
  }

  togglePlayPause(playing: boolean) {
    playing ? this.player.play() : this.player.pause();
  }


  togglePlayPauseOnKeyPress() {
    this.isPlaying ? this.player.pause() : this.player.play();
  }

  updateVideoTime(timePercentage: number) {
    let percentageToTime = Math.ceil((this.player.duration() as number) * (timePercentage / 100));
    this.player.currentTime(percentageToTime);
  }

  /**
   * Initialize Video player
   * @private
   */
  private _initPlayer(videoData: VideoData) {
    if (!this._isPlayerInited && this.videoData) {
      this.player = videojs(this.videoElementRef.nativeElement, {
        preload: 'auto',
        controlBar: false,
        userActions: {doubleClick: false},
        poster: videoData?.poster || undefined,
        autoplay: true,
        sources: videoData.sources
      });

      this._isPlayerInited = true;

      this.player.ready(() => {
        if (videoData.subtitleUrl) {
          this._updateSubtitleSource(videoData.subtitleUrl)
        }
        this._sync();
      });

      this.playing.emit({
        currentTime: Math.trunc(this.player.currentTime() as number),
        playing: false
      })
    } else {
      this._updateVideoUrl(videoData);
    }

  }

  private _showSubtitle() {
    const tracks = (this.player as any).remoteTextTracks();
    for (let i = 0; i < tracks.length; i++) {
      const track = tracks[i];
      if (track.label === 'default') {
        track.mode = this._toggleSubtitle ? 'showing' : 'hidden';
      }
    }
  }

  /**
   * Set and update video url
   * @param videoData
   * @private
   */
  private _updateVideoUrl(videoData: VideoData) {
    if (this.player) {
      this.player.src(videoData.sources);
      this.player.poster(videoData.poster ? videoData.poster : '');
      if (videoData.subtitleUrl) {
        this._updateSubtitleSource(videoData.subtitleUrl)
        this._showSubtitle()
      }
      this._sync();
    }
  }

  /**
   * set volume to the player
   * @param value
   * @private
   */
  private _changeVolume(value: number) {
    this.player.volume(value / 100)
  }

  /**
   * Initialize and fire player events
   * @private
   */
  private _sync() {
    this.player.on(this._events, (e: { type: any; }) => {
      switch (e.type) {
        case PlayerEvents.loadedmetadata:
          this.duration = this._calculateTime(this.player.duration() as number)
          this.videoDuration.emit(this.duration);
          this.player.currentTime(this._playFrom);
          return;

        case PlayerEvents.play :
          this.isPlaying = true;
          this._syncPlay();
          this.playing.emit({
            currentTime: Math.trunc(this.player.currentTime() as number),
            playing: true
          })
          return;

        case PlayerEvents.pause :
          this.isPlaying = false;
          this._syncPause();
          this.playing.emit({
            currentTime: Math.trunc(this.player.currentTime() as number),
            playing: false
          })
          return;


        case PlayerEvents.seeking:
          this._seeking = true;

          this._syncSeek();
          return;

        case PlayerEvents.seeked:
          setTimeout(() => {
            this._seeking = false;
          }, 3000)

          return;

        case PlayerEvents.timeupdate:
          this._currentVideoTimeStream$.next(Math.trunc(this.player.currentTime() as number));
          // this.currentTime = this._calculateTime(this.player.currentTime());
          // this.currentTimeChange.emit(this.currentTime)
          /*if (this.loadedTime < this.currentTime) {
            this.loadedTime = this.currentTime;
          }*/
          break

        case PlayerEvents.ended:
          this.videoEnd.emit()
          return;

        case PlayerEvents.waiting:
          this._waiting = true;
          this._syncPause();
          return;

        case PlayerEvents.playing:
          if (this._waiting) {
            this._syncPlay();
            this._waiting = false;
          }
          return;

        case PlayerEvents.volumechange:
          this._syncVolumeChange();
          return;
      }
    });

    // this._addEventListeners();

  }

  /**
   * Sync video play event with narration and music
   * @private
   */

  private _syncPlay() {
    if (this._narrationAudioPlayer) {
      this._narrationAudioPlayer.play()
        .catch(e => console.log(e, '_narrationAudioPlayer'));
    }

    if (this._musicAudioPlayer) {
      this._musicAudioPlayer.play()
        .catch(e => console.log(e, '_musicAudioPlayer'));
    }
  }

  /**
   * Sync video pause event with narration and music
   * @private
   */
  private _syncPause() {
    if (this._narrationAudioPlayer) {
      this._narrationAudioPlayer.pause();
    }
    if (this._musicAudioPlayer) {
      this._musicAudioPlayer.pause();
    }
  }

  /**
   * Sync video time change event with narration and music
   * @private
   */
  private _syncSeek() {
    const currentTime = Math.trunc(this.player.currentTime() as number);

    if (this._narrationAudioPlayer) {
      this._narrationAudioPlayer.currentTime = currentTime;
    }
    if (this._musicAudioPlayer) {
      this._musicAudioPlayer.currentTime = this._calculateTime(currentTime % this._track.duration);
    }
  }

  /**
   * Sync video volume with narration and music
   * @private
   */
  private _syncVolumeChange() {
    const currentVol = this.player.volume() as number;
    if (this._narrationAudioPlayer) {
      this._narrationAudioPlayer.volume = currentVol;
      this._narrationAudioPlayer.muted = this.player.muted() as boolean;
    }
    if (this._musicAudioPlayer) {
      this._musicAudioPlayer.volume = (currentVol > this._track.volume ? this._track.volume : currentVol);
      this._musicAudioPlayer.muted = this.player.muted() as boolean;
    }

  }

  /**
   * Update/Add video subtitles
   * @param src
   * @private
   */
  private _updateSubtitleSource(src: string) {
    if (src && src.length) {
      const tracks: TextTrackList = (this.player as any).remoteTextTracks();
      for (let i = 0; i < tracks.length; i++) {
        const track = tracks[i];
        if (track.kind === 'captions') {
          this.player.removeRemoteTextTrack(track as any);
        }
      }

      this.player.addRemoteTextTrack({
        kind: 'captions',
        label: 'default',
        src
      }, false);

      for (let i = 0; i < tracks.length; i++) {
        const track = tracks[i];
        if (track.label === 'default') {
          track.mode = this._toggleSubtitle ? 'showing' : 'hidden';
        }
      }

    }

  }

  private _calculateTime(time: number): number {
    return Math.floor(time);
  }

  /*  private _addEventListeners() {
      if (this._narrationAudioPlayer) {
        // this._narrationAudioPlayer.addEventListener(PlayerEvents.loadeddata, e => this._narrationEvent$.next(e));
      }
    }*/

  ngOnDestroy() {
    this._syncPause();
    if (this.player) {
      this.player.pause();
      this.player.dispose();
    }

    if (this._narrationAudioPlayer) {
      this._narrationAudioPlayer.remove();
    }

    if (this._musicAudioPlayer) {
      this._musicAudioPlayer.remove();
    }
  }

}
