<svg width="24" height="24" viewBox="0 0 24 24">
  <g id="arrow-right_svg__Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
    <g id="arrow-right_svg__Stockholm-icons-/-Navigation-/-Right-2">
      <path id="arrow-right_svg__Shape" d="M0 0h24v24H0z"></path>
      <rect id="arrow-right_svg__Rectangle" fill="#FFF" opacity="0.44" transform="rotate(-90 8.5 12)" x="7.5" y="7.5"
            width="2" height="9" rx="1"></rect>
      <path
        d="M9.707 15.707a1 1 0 11-1.414-1.414l6-6a1 1 0 011.383-.03l6 5.5a1 1 0 01-1.352 1.474l-5.294-4.853-5.323 5.323z"
        id="arrow-right_svg__Path-94" fill="#FFF" fill-rule="nonzero" transform="matrix(0 -1 -1 0 27 27)"></path>
    </g>
  </g>
</svg>
