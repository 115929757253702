import { inject, Injectable } from '@angular/core';
import { localStorageKey } from '@models/course-progress-data-types.model';
import { fromEvent, interval, merge, Observable, Subject } from 'rxjs';
import { debounceTime, scan, skip, startWith, switchMapTo, throttleTime } from 'rxjs/operators';
import { selectCourseProgress } from '@root-store/course/course.selectors';
import { Store } from '@ngrx/store';

@Injectable({
  providedIn: 'root'
})
export class IdleTimerService {
  private _idleTimeout = 20;
  // events
  private _keyDown$: Observable<Event>;
  private _click$: Observable<Event>;
  private _touchStart$: Observable<Event>;
  private _mousemove$: Observable<Event>;

  private _resetTrigger$: Subject<boolean>;
  private _events$: Observable<Event | boolean>;
  private _countDownTimer$: Observable<number>;
  private _eventStream$: Observable<number>;

  private _store = inject(Store);


  /**
   * if the course need to reset on time
   * effects will trigger timer init to start events
   * to reset progress on given timeout in milliseconds
   */
  initTimer(timeoutIn: number) {

    console.log('init timer')
    this._idleTimeout = timeoutIn || 50000;
    this._resetCourseProgress();
    this._initEvents();
    this._initStreamSubscriptions();
  }

  /**
   * Initialize to start and rest timer events
   * @private
   */
  private _initEvents() {
    this._keyDown$ = fromEvent(window, 'keydown');
    this._click$ = fromEvent(window, 'click');
    this._touchStart$ = fromEvent(window, 'touchstart');
    this._mousemove$ = fromEvent(window, 'mousemove').pipe(
      debounceTime(50)
    );
    this._resetTrigger$ = new Subject<boolean>();

    // merge events and reset timer to start the event stream
    this._events$ = merge(
      this._keyDown$,
      this._click$,
      this._mousemove$,
      this._touchStart$,
      this._resetTrigger$
    );

    // init countdown timer
    this._countDownTimer$ = interval(1000).pipe(
      startWith(this._idleTimeout),
      scan((acc, value) => acc - 1)
    );

    // init event stream to start the countdown and reset on events
    this._eventStream$ = this._events$.pipe(
      switchMapTo(this._countDownTimer$)
    );
  }

  /**
   * Trigger reset timer
   * @private
   */
  private _resetTimer() {
    this._resetTrigger$.next(true);
  };

  /**
   * subscribe to eventStream to start the timer and
   * reset timer on each course progress trigger ex: video play, go to next slide
   * @private
   */
  private _initStreamSubscriptions() {
    this._eventStream$.subscribe(timer => {
      console.log(timer)
      if (timer === 0) {
        this._resetCourseProgress();
        this._reload();
      }
    })

    this._store.select(selectCourseProgress)
      .pipe(
        skip(3),
        throttleTime(10000),
      ).subscribe(v => {
        this._resetTimer();
    });
  }

  private _resetCourseProgress() {
    localStorage.removeItem(localStorageKey);
  }

  private _reload() {
    window.location.reload();
  }
}
