import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {LayoutComponent} from './layout.component';

import {LayoutFacadeService} from '@core/layout/layout.facade.service';

@NgModule({
    imports: [
    CommonModule,
    LayoutComponent
],
    providers: [
        LayoutFacadeService
    ],
    exports: [
        LayoutComponent,
    ]
})
export class LayoutModule {
}
