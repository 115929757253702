import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';

@Component({
    selector: 'sc-not-completed-icon',
    templateUrl: './not-completed-icon.component.html',
    styleUrls: ['./not-completed-icon.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true
})
export class NotCompletedIconComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
