<section class="layout-container" [ngClass]="layoutType()">
  @if (logoImage()?.fileUrl) {
    <div class="logo-container">
      <img [src]="logoImage()?.fileUrl" [alt]="logoImage()?.fileName" class="logo-image"/>
    </div>
  }
  <div class="graphic" [class.no-bg]="!!graphic()">
    @if (graphic()) {
      <sg-graphic-section [graphic]="graphic()!"/>
    }
  </div>

  <div class="content">
    <ng-content/>
  </div>
</section>
