import {
  ChangeDetectionStrategy, ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input, NgZone, OnDestroy,
  OnInit,
  Output, Renderer2,
  ViewChild
} from '@angular/core';
import { DomHandler } from 'primeng/dom';
import { NgStyle } from '@angular/common';

@Component({
    selector: 'sc-player-timeline',
    templateUrl: './player-timeline.component.html',
    styleUrls: ['./player-timeline.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [NgStyle]
})
export class PlayerTimelineComponent implements OnDestroy {

  @Input() unlockedTimePercentage: number = 0;
  @Input() min: number = 0;
  @Input() max: number = 100;

  @Input() set timePercentage(t: number) {
    this.writeValue(t)
  };

  @Output() onChange: EventEmitter<any> = new EventEmitter();
  @Output() onSlideEnd: EventEmitter<any> = new EventEmitter();

  @ViewChild('sliderHandle') sliderHandle: ElementRef;

  handleValue: number;
  dragging: boolean;
  value: number;

  private _dragListener: any;
  private _mouseupListener: any;
  private _initX: number;
  private _initY: number;
  private _barWidth: number;
  private _barHeight: number;
  private _sliderHandleClick: boolean;
  private _handleIndex: number = 0;



  constructor(public el: ElementRef,
              public renderer: Renderer2,
              private ngZone: NgZone,
              public cd: ChangeDetectorRef) {
  }

  //1
  onMouseDown(event: any, index?: number) {

    this.dragging = true;
    this.updateDomData();
    this._sliderHandleClick = true;
    this._handleIndex = index as number;
    this.bindDragListeners();

    event.target.focus();
    event.preventDefault();
  }


  onBarClick(event: any) {

    if (!this._sliderHandleClick) {
      this.updateDomData();
      this.handleChange(event);
    }

    this._sliderHandleClick = false;
  }

  onHandleKeydown(event: any, handleIndex?: number) {
    if (event.which == 38 || event.which == 39) {
      this.spin(event, 1, handleIndex);
    } else if (event.which == 37 || event.which == 40) {
      this.spin(event, -1, handleIndex);
    }
  }

  spin(event: any, dir: number, handleIndex?: number) {
    this.updateValue(this.value + dir);
    this.updateHandleValue();
    event.preventDefault();
  }

  handleChange(event: MouseEvent) {
    let handleValue = this.calculateHandleValue(event);
    this.setValueFromHandle(event, handleValue);
  }

  //3
  bindDragListeners() {
    this.ngZone.runOutsideAngular(() => {
      const documentTarget: any = this.el ? this.el.nativeElement.ownerDocument : 'document';

      if (!this._dragListener) {
        this._dragListener = this.renderer.listen(documentTarget, 'mousemove', (event) => {
          if (this.dragging) {
            this.ngZone.run(() => {
              this.handleChange(event);
            });
          }
        });
      }

      if (!this._mouseupListener) {
        this._mouseupListener = this.renderer.listen(documentTarget, 'mouseup', (event) => {
          if (this.dragging) {
            this.dragging = false;
            this.ngZone.run(() => {
              this.onSlideEnd.emit(this.value);
            });
          }
        });
      }
    });
  }

  unbindDragListeners() {
    if (this._dragListener) {
      this._dragListener();
    }

    if (this._mouseupListener) {
      this._mouseupListener();
    }
  }

  setValueFromHandle(event: Event, handleValue: any) {
    this._sliderHandleClick = false;
    let newValue = this.getValueFromHandle(handleValue);

    this.updateValue(newValue, event);
    this.handleValue = handleValue;
    this.updateValue(newValue, event);

    this.cd.markForCheck();
  }

  writeValue(value: any): void {
    this.value = value || 0;

    this.updateHandleValue();
    this.cd.markForCheck();
  }

  //2
  updateDomData(): void {
    let rect = this.el.nativeElement.children[0].getBoundingClientRect();
    this._initX = rect.left + DomHandler.getWindowScrollLeft();
    this._initY = rect.top + DomHandler.getWindowScrollTop();
    this._barWidth = this.el.nativeElement.children[0].offsetWidth;
    this._barHeight = this.el.nativeElement.children[0].offsetHeight;
  }

  calculateHandleValue(event: MouseEvent): number {
    return ((event.pageX - this._initX) * 100) / this._barWidth;
  }

  updateHandleValue(): void {
    if (this.value < this.min) this.handleValue = 0;
    else if (this.value > this.max) this.handleValue = 100;
    else this.handleValue = ((this.value - this.min) * 100) / (this.max - this.min);
  }

  updateValue(val: number, event?: Event): void {

    if (this.unlockedTimePercentage < val) {
      this.handleValue = this.unlockedTimePercentage
      val = this.unlockedTimePercentage;
    } else if (val < this.min) {
      val = this.min;
      this.handleValue = 0;
    } else if (val > this.max) {
      val = this.max;
      this.handleValue = 100;
    }

    this.value = this.getNormalizedValue(val);

    // console.log(this.value)

    // this.onModelChange(this.value);

    this.onChange.emit(this.value);
    this.sliderHandle.nativeElement.focus();
  }

  getValueFromHandle(handleValue: number): number {
    return (this.max - this.min) * (handleValue / 100) + this.min;
  }

  getDecimalsCount(value: number): number {
    if (value && Math.floor(value) !== value) return value.toString().split('.')[1].length || 0;
    return 0;
  }

  getNormalizedValue(val: number): number {
    let decimalsCount = this.getDecimalsCount(1);
    if (decimalsCount > 0) {
      return +parseFloat(val.toString()).toFixed(decimalsCount);
    } else {
      return Math.floor(val);
    }
  }

  ngOnDestroy() {
    this.unbindDragListeners();
  }

}
