import {inject, Injectable, signal, WritableSignal} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {Store} from '@ngrx/store';
import {
  courseCompletionTimer,
  selectActiveSlideData,
  selectCourse,
  selectCourseProgress,
  selectLanguage,
  selectPlayerColor
} from '@root-store/course/course.selectors';
import {
  completeCourse,
  saveCourseProgressData,
  setActiveLinkMap,
  showLanguageSelection,
  updateSlideProgressData
} from '@root-store/course/course.actions';

import {
  GroupProgress,
  ProgressDataFactory,
  SlideDataUnit,
  SlideProgress,
  SlideProgressData
} from '@models/course-progress-data-types.model';
import {auditTime, filter, take, tap} from 'rxjs/operators';
import {NavigationFacadeService} from '@core/facades/navigation.facade.service';
import {CourseProgressFacadeService} from '@core/facades/course-progress.facade.service';
import {
  GroupCategory,
  GroupSlide,
  PointerSlide,
  PointerType,
  SlideCategory,
  Slides,
  SlideType
} from '@models/slide.model';
import {Course, CourseConfig} from '@models/course.model';
import {CourseProgress} from "@models/course-progress.model";

@Injectable({
  providedIn: 'root'
})
export class CourseFacadeService {

  course$: Observable<Course>;
  slideDataUnit$: Observable<SlideDataUnit<SlideType>>;
  currentVolume$ = new BehaviorSubject(50);

  subtitle: WritableSignal<boolean> = signal(true);

  courseCompletionTimer$: Observable<{ totalTime: number, completedTime: number }>;
  canGoBack$: Observable<boolean>;
  selectedLanguage$: Observable<string>;
  courseConfig: CourseConfig;

  flattenSlides: Slides = {};
  slideList: SlideType[] = [];
  currentAsi: string = 'title';

  private _currentSlideProgressData: SlideProgress;

  // Injectors
  private _store = inject(Store);
  private _navigationFacade = inject(NavigationFacadeService);
  private _courseProgressFacade = inject(CourseProgressFacadeService);
  // private _dialogService = inject(DialogService)
  courseProgress: CourseProgress;

  constructor() {

    this.canGoBack$ = this._navigationFacade.canGoBack$;

    /********************************/
    this._store.select(selectCourseProgress)
      .pipe(
        filter(data => !!data)
      ).subscribe(courseProgress => this.courseProgress = courseProgress)

    this.course$ = this._store.select(selectCourse).pipe(
      filter((course): course is NonNullable<typeof course> => !!course),
      tap(course => this.courseConfig = course.config as CourseConfig)
    ) as Observable<Course>;

    this.slideDataUnit$ = this._store.select(selectActiveSlideData).pipe(
      filter((slide): slide is NonNullable<typeof slide> => !!slide),
      tap(slideData => this._currentSlideProgressData = (slideData as any).progressData)
    );

    this.courseCompletionTimer$ = this._store.select(courseCompletionTimer);
    this.selectedLanguage$ = this._store.select(selectLanguage);


    /**
     * set state color
     */
    this._store.select(selectPlayerColor).pipe(
      filter(color => !!color),
      take(1)
    ).subscribe(
      color => this.setPrimaryColor(color)
    );

    /**
     * call to save data
     */
    this._store.select(selectCourseProgress).pipe(
      auditTime(3000),
    ).subscribe(progressData => {
      this._store.dispatch(saveCourseProgressData({progressData}))
    });
  }

  /**
   * Save Slide Progress Data
   * @param slideProgress
   */
  saveSlideProgressData(slideProgress: SlideProgress) {

    this._currentSlideProgressData = slideProgress;

    const activeSlide = this.flattenSlides[slideProgress.id];

    const slideDataCopy = JSON.parse(JSON.stringify(this.courseProgress.slideData));

    if (slideDataCopy[slideProgress.id].category === SlideCategory.GROUP) {

      slideDataCopy[slideProgress.id] = slideProgress;
      const isAllContentsAreUnlocked = (slideDataCopy[slideProgress.id] as GroupProgress)
        .cRef
        .every(id => slideDataCopy[id].slideCompleted)

      if (isAllContentsAreUnlocked) {
        slideDataCopy[slideProgress.id].slideCompleted = isAllContentsAreUnlocked;
        slideDataCopy[slideProgress.id].isSlideUnlocked = isAllContentsAreUnlocked;
      }

    } else {
      slideDataCopy[slideProgress.id] = slideProgress;
    }


    if (activeSlide.lastItemInLink || (activeSlide as PointerSlide).pointerType == PointerType.POPUP) {
      const flatIds = activeSlide.link.flat(1);
      this.updateSubProgress(flatIds, slideDataCopy, activeSlide)
    }

    this._store.dispatch(updateSlideProgressData({slideProgressData: slideDataCopy, slideProgress: slideProgress}));
  }

  updateSubProgress(flatIds: string[], slideDataCopy: SlideProgressData, activeSlide: any) {
    const linkDeep = flatIds.length;

    const tempTile = flatIds[flatIds.length - 2];
    const tempRoot = flatIds[flatIds.length - 3];

    let isAllChildAreUnlockedInRoot: boolean;
    let isAllChildAreUnlockedInTile: boolean;

    if ((linkDeep % 2) === 1 && linkDeep !== 1) {

      isAllChildAreUnlockedInTile = (slideDataCopy[tempTile] as GroupProgress)
        .cRef
        .every(id => slideDataCopy[id].slideCompleted);


      if (isAllChildAreUnlockedInTile) {
        slideDataCopy[tempTile].slideCompleted = isAllChildAreUnlockedInTile;
        slideDataCopy[tempTile].isSlideUnlocked = isAllChildAreUnlockedInTile;

        const nextTile = (slideDataCopy[tempRoot] as GroupProgress).cRef[((slideDataCopy[tempRoot] as GroupProgress).cRef.indexOf(tempTile)) + 1];

        if (nextTile) {
          (slideDataCopy[nextTile] as GroupProgress).isLock = false
        }
      }

      isAllChildAreUnlockedInRoot = (slideDataCopy[tempRoot] as GroupProgress)
        .cRef
        .every(id => slideDataCopy[id].slideCompleted);

      if (isAllChildAreUnlockedInRoot) {
        slideDataCopy[tempRoot].slideCompleted = isAllChildAreUnlockedInRoot;
        slideDataCopy[tempRoot].isSlideUnlocked = isAllChildAreUnlockedInRoot;
      }

      flatIds.splice(flatIds.length - 2, 2);

      this.updateSubProgress(flatIds, slideDataCopy, activeSlide)

    }

    //special case for popup pointer
    if (flatIds.length >= 2) {
      if (activeSlide?.subCategory == GroupCategory.POINTER && (activeSlide as PointerSlide).pointerType == PointerType.POPUP) {
        const isAllContentsAreUnlocked = (slideDataCopy[flatIds[flatIds.length - 2]] as GroupProgress)
          .cRef
          .every(id => slideDataCopy[id].slideCompleted);

        if (isAllContentsAreUnlocked) {
          slideDataCopy[flatIds[flatIds.length - 2]].slideCompleted = isAllContentsAreUnlocked;
          slideDataCopy[flatIds[flatIds.length - 2]].isSlideUnlocked = isAllContentsAreUnlocked;
        }

        flatIds.splice(flatIds.length - 1, 1);

        this.updateSubProgress(flatIds, slideDataCopy, activeSlide)
      }
    }
  }

  /**
   * navigate next
   */
  navigateToNextSlide(dataUnit?: string) {
    const nextSlide = this._navigationFacade.getNextSlide(dataUnit);

    if (nextSlide.id) {
      this._store.dispatch(setActiveLinkMap({
        asi: nextSlide.id,
        cpi: nextSlide.index
      }))
    }

  }

  /**
   * navigate back
   */
  navigateToPreviousSlide() {
    const previousSlide = this._navigationFacade.getPreviousSlide();
    let previousSlideId = previousSlide.id;
    /*if (previousSlide.type == CourseSlideType.TITLE) {
      previousSlideId = CourseSlideType.TITLE;
    }*/

    this._store.dispatch(setActiveLinkMap({
      asi: previousSlideId,
      cpi: previousSlide.index
    }))
  }


  /**
   * change video volume
   * @param volume
   */
  changeVolume(volume: number) {
    this.currentVolume$.next(volume)
  }

  /**
   * change video volume
   * @param subtitle
   */
  toggleSubtitle(subtitle: boolean) {
    this.subtitle.set(subtitle)
  }

  /**
   * Select Language
   */
  selectLanguage() {
    this._store.dispatch(showLanguageSelection())
  }

  /**
   * Complete Course
   */
  completeCourse() {
    this._store.dispatch(completeCourse())
  }


  /**
   * set course color
   * @param color
   */
  setPrimaryColor(color?: string) {
    if (color) {
      document.documentElement.style.setProperty('--sg-primary-color', color);
      document.documentElement.style.setProperty('--sg-primary-light-color', color + 'B3');
    }
  }

  processSlides(slides: Slides) {
    this._createSlideList(slides);
    this.slideList = this._processSlideIndex(this.slideList);
    this.currentAsi = this.slideList[0].id;
    this.flattenSlides = {}

    this.slideList.forEach((slide, index) => {

      let slideCopy: SlideType = {...slide};
      slideCopy.index = index;
      if (slideCopy.category === SlideCategory.GROUP) {
      }
      this.flattenSlides[slideCopy.id] = slideCopy;
    });
    this._navigationFacade.initNavigation(this.slideList);
    return this.flattenSlides;
  }

  selectGroupDataUnitList<T = GroupSlide>(slideId: string) {
    let groupChildIds: string[] = [];
    if (this.flattenSlides[slideId].category === SlideCategory.GROUP) {
      groupChildIds = Object.keys((this.flattenSlides[slideId] as GroupSlide).content)
    }

    const groupDataUnits: SlideDataUnit<GroupSlide>[] = ProgressDataFactory
      .createGroupChildDataUnitList(
        groupChildIds,
        this.flattenSlides,
        this._courseProgressFacade.courseProgress.slideData
      );
    return groupDataUnits;
  }

  selectSubGroup(tileId: string) {
    this.navigateToNextSlide(tileId);
  }


  private _createSlideList(slides: Slides) {
    const slideList = this._getSlideAsList(JSON.parse(JSON.stringify(slides)));

    slideList.forEach((slide, index) => {
      slide.lastItemInLink = (slideList.length === (index + 1));
      this.slideList.push(slide)
      // Root Level ---------------------------->
      if (slide.category === SlideCategory.GROUP) {
        // Content --------------------->
        const groupContentList = this._getSlideAsList(slide.content || {});

        groupContentList.forEach((content, tileIndex) => {
          content.firstItemInLink = (tileIndex === 0);
          content.lastItemInLink = groupContentList.length === (tileIndex + 1);

          if ((content as GroupSlide)) {
            this.slideList.push(content);
            // Group Content --------------------------->
            this._createSlideList((content as GroupSlide).content);
          }
        })
        // Tiles <----------------------------
      }
    })
  }

  private _getSlideAsList(slides: Slides): SlideType[] {
    return Object
      .values(slides || {})
      .sort((a, b) => a.index - b.index);
  }

  private _processSlideIndex(slideNavList: SlideType[]): SlideType[] {
    return slideNavList.map((slide, index) => {
      const slideCopy = {...slide}
      slideCopy.index = index;
      return slideCopy;
    })
  }
}

