import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EffectsModule } from '@ngrx/effects';
import { CourseEffects } from './course/course.effects';
import { RouterModule } from '@angular/router';



@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    EffectsModule.forRoot([
      CourseEffects
    ]),

  ]
})
export class EffectsRegistryModule { }
