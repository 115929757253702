import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { SlideComponent } from './slide/slide.component';

@Component({
    selector: 'sc-course',
    templateUrl: './course.component.html',
    styleUrls: ['./course.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [SlideComponent]
})
export class CourseComponent implements OnInit {



  constructor(private _store: Store) {

  }

  ngOnInit(): void {
  }

}
