import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {CourseFacadeService} from "@core/facades/course.facade.service";
import { VolumeSliderComponent } from './volume-slider/volume-slider.component';
import { VolumeIconComponent } from './volume-icon/volume-icon.component';

@Component({
    selector: 'sc-volume-control',
    templateUrl: './volume-control.component.html',
    styleUrls: ['./volume-control.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [VolumeSliderComponent, VolumeIconComponent]
})
export class VolumeControlComponent implements OnInit {

  volumeLevel: number = 50;
  currentVolumeLevel: number;

  constructor(private _courseFacade: CourseFacadeService) {
  }

  ngOnInit(): void {
  }

  setVolume(volume: number) {
    this.currentVolumeLevel = this.volumeLevel;
    this.volumeLevel = volume;
    this._courseFacade.changeVolume(volume)
  }
}
