import { Pipe, PipeTransform } from '@angular/core';
import {Translation} from "@models/course.model";
import translationData from "@assets/json/translation.json";

@Pipe({
    name: 'percentage',
    standalone: true
})
export class PercentagePipe implements PipeTransform {

  translations: { [key: string]: Translation }

  constructor() {
    this.translations = translationData;
  }

  transform(completedTime: number, totalTime: number, languageCode: string | null): unknown {

    if (!languageCode) {
      return '--:--';
    }

    const translation = this.translations[languageCode] || this.translations['en'];
    return ((100 * completedTime) / totalTime).toFixed(0) + '% ' + translation.completed;
  }

}
