<div class="answer-button">
  <div class="answer-number">

    @if (!answered) {
      {{ index + 1 }}
    }

    @if (answered) {
      <div class="lottie-container">
        <ng-lottie [options]="{ path: (answerStatus ? 'assets/lottie/check_green.json': 'assets/lottie/cross_red.json'),  loop: false}"/>
      </div>
    }

  </div>
  <div class="answer-text">{{ answer.answer }}</div>
</div>
