import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Observable, of} from 'rxjs';
import {
  catchError,
  debounceTime,
  filter,
  map,
  mergeMap,
  switchMap,
  tap, throttleTime
} from 'rxjs/operators';

import {
  completeCourse,
  getCourseById,
  getCourseByIdFailure,
  getCourseByIdSuccess,
  getLanguages,
  getLanguagesFailure,
  getLanguagesSuccess,
  initializeCourse,
  resumeCourse,
  saveCourseProgressData,
  selectLanguage,
  showCourse,
  showLanguageSelection,
  startCourseFromBeginning,
  updateSlideProgressData
} from './course.actions';
import {CourseService} from '@core/services/course.service';
import {CourseProgressDataService} from '@core/services/course-progress-data.service';
import {ScormService} from '@core/services/scorm.service';
import {CourseProgress} from '@models/course-progress.model';
import {LanguageService} from '@core/services/language.service';
import {CourseFacadeService} from '@core/facades/course.facade.service';
import {TimelineFacadeService} from '@core/facades/timeline.facade.service';
import {Course} from '@models/course.model';
import {ActivatedRoute} from '@angular/router';
import {NavigationFacadeService} from '@core/facades/navigation.facade.service';
import {IdleTimerService} from '@core/services/idle-timer.service';

@Injectable()
export class CourseEffects {

  private _isScormEnv = false;
  private _courseId?: string;
  private _previewMode?: boolean;
  private _canSkip?: boolean;
  private _skipMode?: boolean;

  /************************************************************************
   * Init course
   */
  initializeCourse$ = createEffect(() => this._actions$.pipe(
    ofType(initializeCourse),
    mergeMap(_ => {
      return this._router.queryParams.pipe(
        debounceTime(5)
      )
    }),
    mergeMap(({cid, prv, skipMode, isTimeout, timeoutIn}) => {
      this._courseId = cid;
      this._previewMode = (prv === 'true')
      this._skipMode = (skipMode === 'true');

      if ((isTimeout === 'true')) {
        this._idleTimeService.initTimer(parseInt(timeoutIn));
      }

      return this._languageService.getLanguages(this._courseId).pipe(
        switchMap((data: any) => {

          // check if is preview and course id is available
          if (this._previewMode && this._courseId) {

            if (data.languages.length > 1) {
              return of(startCourseFromBeginning())
            }
            return of(selectLanguage({languageCode: data.languages[0].code}))
          }

          // else read course progress
          return this._readCourseProgress().pipe(
            map(courseProgress => {
              if (!courseProgress) {
                if (data.languages.length > 1) {
                  return startCourseFromBeginning();
                }
                return selectLanguage({languageCode: data.languages[0].code});
              }
              return resumeCourse({courseProgress})
            })
          );
        })
      )
    })
  ))

  /************************************************************************
   * Start Course From Beginning
   * Redirect to language page
   */
  startCourseFromBeginning$ = createEffect(() => this._actions$.pipe(
    ofType(startCourseFromBeginning),
    map(() => showLanguageSelection())
  ))

  /************************************************************************
   * Resume course
   */
  resumeCourse$ = createEffect(() => this._actions$.pipe(
    ofType(resumeCourse),
    mergeMap(({courseProgress}) => {
      this._navigationFacade.setCurrentPointerIndex(courseProgress.cpi);
      return this._readCourseProgress().pipe(
        map(courseProgress => {
          return getCourseById({
            courseId: this._courseId ? this._courseId : null,
            languageCode: courseProgress.languageCode
          })
        })
      )
    })
  ))

  /************************************************************************
   * Get Languages
   */
  getLanguages$ = createEffect(() => this._actions$.pipe(
    ofType(getLanguages),
    switchMap(() => {
      return this._languageService.getLanguages(this._courseId).pipe(
        map((data) => getLanguagesSuccess({data})),
        catchError(error => of(getLanguagesFailure({error})))
      )
    })
  ),)

  getLanguagesSuccess$ = createEffect(() => this._actions$.pipe(
    ofType(getLanguagesSuccess),
    tap(({data}) => {
      this._courseFacadeService.setPrimaryColor(data.config.primaryColor)
    })
  ), {dispatch: false})

  /************************************************************************
   * Select Language
   */
  selectLanguage$ = createEffect(() => this._actions$.pipe(
    ofType(selectLanguage),
    map(({languageCode}) => {
      return getCourseById({
        courseId: !this._courseId ? null : this._courseId,
        languageCode
      })
    })
  ))


  /************************************************************************
   * Show Language Selection
   */
  loadCourseById$ = createEffect(() => this._actions$.pipe(
    ofType(getCourseById),
    mergeMap(({courseId, languageCode}) => {
      return this._courseService.getCourse(courseId, languageCode).pipe(
        map((course: Course) => {
          // let copy = JSON.parse(JSON.stringify(course))
          // delete copy.slides['title'];

          const flattenSlides = this._courseFacadeService.processSlides(course.slides);
          const courseCopy: Course = {
            ...course,
            slides: flattenSlides
          }

          this._canSkip = course.config?.canSkip;
          console.log(this._skipMode || course.config?.canSkip)
          this._navigationFacade.skipMode.set(<boolean>(this._skipMode || course.config?.canSkip))
          return getCourseByIdSuccess({
            course: courseCopy,
            languageCode: languageCode,
            asi: this._courseFacadeService.currentAsi,
            isUnlocked: this._skipMode || course.config?.canSkip
          })
        }),
        catchError(() => {
          return of(getCourseByIdFailure())
        })
      );
    })
  ))

  /************************************************************************
   * Show Course
   */
  getCourseByIdSuccess$ = createEffect(() => this._actions$.pipe(
    ofType(getCourseByIdSuccess),
    map(() => showCourse())
  ))
  /************************************************************************
   * Update Slide Progress Data
   */

  // filter(({slideProgress}) => slideProgress.isSlideUnlocked && !slideProgress.slideCompleted),

  updateSlideProgressData$ = createEffect(() => this._actions$.pipe(
    ofType(updateSlideProgressData),
    filter(({slideProgressData}) => {
      return true
      /*switch (slideProgress.category as S) {
        case CourseSlideType.VIDEO:
          return slideProgress.duration === (slideProgress as VideoProgress).currentTime;
        case CourseSlideType.TITLE:
          return false
        default:
          return true
      }*/
    }),
    tap(({slideProgressData}) => {
      this._timelineFacade.initCountdownTimer(0, 3);
    })
  ), {dispatch: false})

  /************************************************************************
   * Save Progress Data
   */
  saveProgressData$ = createEffect(() => this._actions$.pipe(
    ofType(saveCourseProgressData),
    tap(({progressData}) => {
      if (this._isScormEnv) {
        this._scormService.setData(progressData);
        return;
      }
      if (!this._previewMode) {
        this._courseProgressDataService.saveDataInLocalStorage(progressData)
      }
    }),
    throttleTime(9000),
    tap(() => {
      if (this._isScormEnv) {
        console.log('committing...')
        this._scormService.commit();
      }
    })
  ), {dispatch: false})


  /************************************************************************
   * Complete Course
   */
  completeCourse$ = createEffect(() => this._actions$.pipe(
    ofType(completeCourse),
    tap(() => {
      if (this._isScormEnv) {
        this._scormService.completeCourse();
      }
    })
  ), {dispatch: false})


  constructor(private _actions$: Actions,
              private _courseService: CourseService,
              private _courseFacadeService: CourseFacadeService,
              private _navigationFacade: NavigationFacadeService,
              private _courseProgressDataService: CourseProgressDataService,
              private _scormService: ScormService,
              private _languageService: LanguageService,
              private _timelineFacade: TimelineFacadeService,
              private _router: ActivatedRoute,
              private _idleTimeService: IdleTimerService) {
    this._isScormEnv = _scormService.isScormEnv;
    console.log('isScormEnv?', this._isScormEnv)
  }

  /**
   * Read course progress
   * @private
   */
  private _readCourseProgress(): Observable<CourseProgress> {
    if (this._isScormEnv) {
      return this._scormService.getData().pipe(
        tap(_ => {
          if (this._canSkip) {
            this._scormService.completeCourse();
          }
        })
      );
    }

    return this._courseProgressDataService.getDataFromLocalStorage();
  }

}
