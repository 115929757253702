import { NgModule } from '@angular/core';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';

import { LayoutModule } from './layout/layout.module';
import { CourseService } from './services/course.service';
import { CourseProgressDataService } from './services/course-progress-data.service';
import { ScormService } from './services/scorm.service';
import { ConvertSecondsToMinuteStringPipe } from './pipes/convert-seconds-to-minute-string.pipe';
import { TimerService } from './services/timer.service';
import { DialogService } from 'primeng/dynamicdialog';


@NgModule({
    exports: [
        ConvertSecondsToMinuteStringPipe
    ], imports: [LayoutModule, ConvertSecondsToMinuteStringPipe], providers: [
        CourseService,
        CourseProgressDataService,
        ScormService,
        TimerService,
        DialogService,
        provideHttpClient(withInterceptorsFromDi())
    ] })
export class CoreModule {
}
