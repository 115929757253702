import { NgModule } from '@angular/core';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { StoreModule } from '@ngrx/store';
import { metaReducers, reducers } from './index';
import { environment } from '@env/environment';
import { EffectsRegistryModule } from './effects-registry.module';



@NgModule({
  declarations: [],
  imports: [
    EffectsRegistryModule,
    StoreModule.forRoot(
      reducers, {
        metaReducers,
        runtimeChecks: {
          strictStateImmutability: true,
          strictActionImmutability: true,
        },
      }),
    (!environment.production ? StoreDevtoolsModule.instrument({
      maxAge: 50
    , connectInZone: true}) : []),
  ]
})
export class RootStoreModule { }
