import { ChangeDetectionStrategy, Component, input, InputSignal } from '@angular/core';
import { GraphicSectionComponent } from './graphic-section/graphic-section.component';
import { LayoutType } from '@models/slide.model';
import { NgClass } from '@angular/common';
import { GraphicItem } from '@models/asset.model';


@Component({
  selector: 'sg-layout-template',
  standalone: true,
  imports: [
    GraphicSectionComponent,
    GraphicSectionComponent,
    NgClass,
  ],
  templateUrl: './layout-template.component.html',
  styleUrl: './layout-template.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LayoutTemplateComponent {

  layoutType: InputSignal<LayoutType> = input.required();
  graphic: InputSignal<GraphicItem | undefined> = input();
  logoImage: InputSignal<GraphicItem | undefined> = input();

  protected readonly LayoutType = LayoutType;
}
