import { ActionReducerMap, MetaReducer } from '@ngrx/store';

import { environment } from '@env/environment';
import * as fromCourse from './course/course.reducer'
import { CourseState } from '@models/course-state.model';

export interface AppState {
  [fromCourse.courseFeatureKey]: CourseState
}

export const reducers: ActionReducerMap<AppState> = {
  [fromCourse.courseFeatureKey]: fromCourse.courseReducer
};


export const metaReducers: MetaReducer<AppState>[] = !environment.production ? [] : [];
