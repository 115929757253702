<video width="100%"
       (contextmenu)="hide()"
       [autoplay]="autoplay"
       controls #videoElementRef
       class="video-js vjs-16-9 vjs-big-play-centered">
<!--  <track kind="captions" src="https://adme-bucket-prod.s3.eu-de.cloud-object-storage.appdomain.cloud/workspace_files/atlas_copco/ee6e6183-c0ea-4d72-8713-480686afc141/772_ATLASCOPCO_LEARNINGCULTURE_final_h264.en_EN.vtt" srclang="en" label="English" default>-->
</video>

<sc-video-controls [playingStatus]="!player.paused()"
                   [unlockedTime]="unlockedTime"
                   [currentTime]="(timeUpdate$ | async)!"
                   [duration]="duration"
                   (updateTime)="updateVideoTime($event)"
                   (togglePlayEvent)="togglePlayPause($event)"
                   (window:keyup.space)="togglePlayPauseOnKeyPress()">
</sc-video-controls>

<div class="no-video" *ngIf="!videoData">
  <div class="tag">Video File not Selected</div>
</div>
