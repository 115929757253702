import {ChangeDetectionStrategy, Component, input, Input, InputSignal} from '@angular/core';
import {TileSlide} from "@models/slide.model";
import {AnimationComponent} from "./animation/animation.component";
import {BaseComponent} from "./base/base.component";
import {EventsComponent} from "./events/events.component";
import {NgClass} from "@angular/common";
import {GroupProgress, SlideProgress} from "@models/course-progress-data-types.model";

@Component({
  selector: 'sg-tile-slide',
  standalone: true,
  imports: [
    AnimationComponent,
    BaseComponent,
    EventsComponent,
    NgClass
  ],
  templateUrl: './tile-slide.component.html',
  styleUrl: './tile-slide.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TileSlideComponent {

  tileSlide: InputSignal<TileSlide> = input.required();

  isLock:boolean;

  @Input() set slideProgress (slideProgress: SlideProgress){
    this.isLock = (slideProgress as GroupProgress).isLock
  };

  @Input() isUnlocked: boolean;
  @Input() tileIndex: number;
  @Input() showTitle: boolean = false;
  empty:InputSignal<boolean> = input(false);

}
