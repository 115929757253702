import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { InformationDialogData } from '@models/popup.model';

@Component({
  selector: 'sg-information-dialog',
  standalone: true,
  templateUrl: './information-dialog.component.html',
  styleUrl: './information-dialog.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InformationDialogComponent {

  private _dialogConfig: DynamicDialogConfig<InformationDialogData> = inject(DynamicDialogConfig);
  private _dialogRef = inject(DynamicDialogRef);

  dialogData = this._dialogConfig!!.data;

  closeDialog() {
    this._dialogRef.close(false);
  }
}
