<div class="timeline"
     (click)="onBarClick($event)">
  <span class="timeline-range"
        [ngStyle]="{ width: handleValue + '%' }">
  </span>
  <span class="unlocked-time" [ngStyle]="{width: unlockedTimePercentage + '%'}"></span>
  <span #sliderHandle
        (keydown)="onHandleKeydown($event)"
        class="timeline-handle "
        (mousedown)="onMouseDown($event)"
        [ngStyle]="{ left: handleValue + '%' }"
        [style.transition]="dragging ? 'none' : null"
        [attr.aria-valuemin]="min"
        [attr.aria-valuenow]="value"
        [attr.aria-valuemax]="max">
  </span>
</div>
