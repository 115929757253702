import {
  Directive,
  effect,
  ElementRef,
  input,
  InputSignal,
  Renderer2,
  ViewChild
} from '@angular/core';
import {BaseComponent} from "../tile-slide/base/base.component";

@Directive({
  selector: '[tileDecorator]',
  standalone: true,
})
export class TileDecoratorDirective {

  tileData: InputSignal<{ index: number, count: number, isGrid: boolean }> = input.required();

  @ViewChild(BaseComponent) baseComponent: BaseComponent;

  constructor(private _renderer: Renderer2,
              private _hostElement: ElementRef) {
    effect(() => {
      const {count, index, isGrid} = this.tileData();
      this._update(count, index, isGrid);
    })
  }

  private _update(tileCount: number, tileIndex: number, isGrid: boolean) {

    const nativeElement = this._hostElement.nativeElement;
    const rect: DOMRect = this._hostElement.nativeElement.getBoundingClientRect();
    const {width, height} = rect;

    const clientWidth = width;
    const clientHeight = height;
    const divider = 2;
    const gutter = 0.8;
    const unit = 'px';


    switch (tileCount) {
      case 1: {
        this._renderer.setStyle(nativeElement, 'left', `calc(50% - ${clientWidth / divider}${unit})`);
        this._renderer.setStyle(nativeElement, 'top', `calc(50% - ${clientHeight / divider}${unit})`);
        break;
      }

      case 2: {
        switch (tileIndex) {
          case 0:
            this._renderer.setStyle(nativeElement, 'left', `calc(50% - ${clientWidth + gutter}${unit})`);
            this._renderer.setStyle(nativeElement, 'top', `calc(50% - ${clientHeight / divider}${unit})`);
            break;
          case 1:
            this._renderer.setStyle(nativeElement, 'right', `calc(50% - ${clientWidth + gutter}${unit})`);
            this._renderer.setStyle(nativeElement, 'top', `calc(50% - ${clientHeight / divider}${unit})`);
            break;
        }
        break;
      }

      case 3: {
        if (isGrid) {
          switch (tileIndex) {
            case 0:
              this._renderer.setStyle(nativeElement, 'left', `calc(50% - ${(clientWidth * 1.5) + (gutter * 2)}${unit})`);
              this._renderer.setStyle(nativeElement, 'top', `calc(50% - ${clientHeight / divider}${unit})`);
              this._renderer.setStyle(nativeElement, 'z-index', 2);
              break;
            case 1:
              this._renderer.setStyle(nativeElement, 'left', `calc(50% - ${clientWidth / divider}${unit})`);
              this._renderer.setStyle(nativeElement, 'top', `calc(50% - ${clientHeight / divider}${unit})`);
              this._renderer.setStyle(nativeElement, 'z-index', 2);
              break;
            case 2:
              this._renderer.setStyle(nativeElement, 'right', `calc(50% - ${(clientWidth * 1.5) + (gutter * 2)}${unit})`);
              this._renderer.setStyle(nativeElement, 'top', `calc(50% - ${clientHeight / divider}${unit})`);
              this._renderer.setStyle(nativeElement, 'z-index', 2);
              break;
          }
          break;
        } else {
          switch (tileIndex) {
            case 0:
              this._renderer.setStyle(nativeElement, 'left', `calc(50% - ${clientWidth / divider}${unit})`);
              this._renderer.setStyle(nativeElement, 'top', `calc(50% - ${clientHeight + gutter}${unit})`);
              this._renderer.setStyle(nativeElement, 'z-index', 2);
              break;
            case 1:
              this._renderer.setStyle(nativeElement, 'left', `calc(50% - ${clientWidth + gutter}${unit})`);
              this._renderer.setStyle(nativeElement, 'top', `calc(50% - ${clientHeight / divider}${unit})`);
              this._renderer.setStyle(nativeElement, 'z-index', 3);
              break;
            case 2:
              this._renderer.setStyle(nativeElement, 'right', `calc(50% - ${clientWidth + gutter}${unit})`);
              this._renderer.setStyle(nativeElement, 'top', `calc(50% - ${clientHeight / divider}${unit})`);
              this._renderer.setStyle(nativeElement, 'z-index', 3);
              break;
          }
          break;
        }

      }

      case 4: {
        if (isGrid) {
          switch (tileIndex) {
            case 0:
              this._renderer.setStyle(nativeElement, 'left', `calc(50% - ${clientWidth + gutter}${unit})`);
              this._renderer.setStyle(nativeElement, 'top', `calc(50% - ${clientHeight + gutter}${unit})`);
              this._renderer.setStyle(nativeElement, 'z-index', 2);
              break;
            case 1:
              this._renderer.setStyle(nativeElement, 'right', `calc(50% - ${clientWidth + gutter}${unit})`);
              this._renderer.setStyle(nativeElement, 'top', `calc(50% - ${clientHeight + gutter}${unit})`);
              this._renderer.setStyle(nativeElement, 'z-index', 2);
              break;
            case 2:
              this._renderer.setStyle(nativeElement, 'left', `calc(50% - ${clientWidth + gutter}${unit})`);
              this._renderer.setStyle(nativeElement, 'bottom', `calc(50% - ${clientHeight + gutter}${unit})`);
              this._renderer.setStyle(nativeElement, 'z-index', 3);
              break;
            case 3:
              this._renderer.setStyle(nativeElement, 'right', `calc(50% - ${clientWidth + gutter}${unit})`);
              this._renderer.setStyle(nativeElement, 'bottom', `calc(50% - ${clientHeight + gutter}${unit})`);
              this._renderer.setStyle(nativeElement, 'z-index', 3);
              break;
          }
          break;
        } else {
          switch (tileIndex) {
            case 0:
              this._renderer.setStyle(nativeElement, 'left', `calc(50% - ${(clientWidth / divider)}${unit})`);
              this._renderer.setStyle(nativeElement, 'top', `calc(50% - ${clientHeight + gutter}${unit})`);
              this._renderer.setStyle(nativeElement, 'z-index', 2);
              break;
            case 1:
              this._renderer.setStyle(nativeElement, 'left', `calc(50% - ${clientWidth + divider}${unit})`);
              this._renderer.setStyle(nativeElement, 'bottom', `calc(50% - ${clientHeight / divider}${unit})`);
              this._renderer.setStyle(nativeElement, 'z-index', 3);
              break;
            case 2:
              this._renderer.setStyle(nativeElement, 'right', `calc(50% - ${clientWidth + gutter}${unit})`);
              this._renderer.setStyle(nativeElement, 'top', `calc(50% - ${(clientHeight / divider)}${unit})`);
              this._renderer.setStyle(nativeElement, 'z-index', 3);
              break;
            case 3:
              this._renderer.setStyle(nativeElement, 'right', `calc(50% - ${clientWidth / divider}${unit})`);
              this._renderer.setStyle(nativeElement, 'bottom', `calc(50% - ${clientHeight + gutter}${unit})`);
              this._renderer.setStyle(nativeElement, 'z-index', 4);
              break;
          }
          break;
        }

      }

      case 5: {
        if (isGrid) {
          switch (tileIndex) {

            case 0:
              this._renderer.setStyle(nativeElement, 'left', `calc(50% - ${(clientWidth * 1.5) + (gutter * 2)}${unit})`);
              this._renderer.setStyle(nativeElement, 'top', `calc(50% - ${clientHeight + gutter}${unit})`);
              this._renderer.setStyle(nativeElement, 'z-index', 2);
              break;
            case 1:
              this._renderer.setStyle(nativeElement, 'left', `calc(50% - ${clientWidth / divider}${unit})`);
              this._renderer.setStyle(nativeElement, 'top', `calc(50% - ${clientHeight + gutter}${unit})`);
              this._renderer.setStyle(nativeElement, 'z-index', 2);
              break;
            case 2:
              this._renderer.setStyle(nativeElement, 'right', `calc(50% - ${(clientWidth * 1.5) + (gutter * 2)}${unit})`);
              this._renderer.setStyle(nativeElement, 'top', `calc(50% -  ${clientHeight + gutter}${unit})`);
              this._renderer.setStyle(nativeElement, 'z-index', 2);
              break;
            case 3:
              this._renderer.setStyle(nativeElement, 'left', `calc(50% - ${clientWidth + gutter}${unit})`);
              this._renderer.setStyle(nativeElement, 'bottom', `calc(50% - ${clientHeight + gutter}${unit})`);
              this._renderer.setStyle(nativeElement, 'z-index', 3);
              break;
            case 4:
              this._renderer.setStyle(nativeElement, 'right', `calc(50% - ${clientWidth + gutter}${unit})`);
              this._renderer.setStyle(nativeElement, 'bottom', `calc(50% - ${clientHeight + gutter}${unit})`);
              this._renderer.setStyle(nativeElement, 'z-index', 3);
              break;
          }
          break;
        } else {
          switch (tileIndex) {

            case 0:
              this._renderer.setStyle(nativeElement, 'left', `calc(50% - ${clientWidth + gutter}${unit})`);
              this._renderer.setStyle(nativeElement, 'top', `calc(50% - ${clientHeight + gutter}${unit})`);
              this._renderer.setStyle(nativeElement, 'z-index', 2);
              break;
            case 1:
              this._renderer.setStyle(nativeElement, 'right', `calc(50% - ${clientWidth + gutter}${unit})`);
              this._renderer.setStyle(nativeElement, 'top', `calc(50% - ${clientHeight + gutter}${unit})`);
              this._renderer.setStyle(nativeElement, 'z-index', 2);
              break;
            case 2:
              this._renderer.setStyle(nativeElement, 'left', `calc(50% - ${clientWidth / divider}${unit})`);
              this._renderer.setStyle(nativeElement, 'top', `calc(50% - ${clientHeight / divider}${unit})`);
              this._renderer.setStyle(nativeElement, 'z-index', 3);
              break;
            case 3:
              this._renderer.setStyle(nativeElement, 'left', `calc(50% - ${clientWidth + gutter}${unit})`);
              this._renderer.setStyle(nativeElement, 'bottom', `calc(50% - ${clientHeight + gutter}${unit})`);
              this._renderer.setStyle(nativeElement, 'z-index', 4);
              break;
            case 4:
              this._renderer.setStyle(nativeElement, 'right', `calc(50% - ${clientWidth + gutter}${unit})`);
              this._renderer.setStyle(nativeElement, 'bottom', `calc(50% - ${clientHeight + gutter}${unit})`);
              this._renderer.setStyle(nativeElement, 'z-index', 4);
              break;
          }
          break;
        }

      }
      case 6: {
        if (isGrid) {
          switch (tileIndex) {
            case 0:
              this._renderer.setStyle(nativeElement, 'left', `calc(50% - ${(clientWidth * 1.5) + (gutter * 2)}${unit})`);
              this._renderer.setStyle(nativeElement, 'top', `calc(50% - ${clientHeight + gutter}${unit})`);
              this._renderer.setStyle(nativeElement, 'z-index', 2);
              break;
            case 1:
              this._renderer.setStyle(nativeElement, 'left', `calc(50% - ${clientWidth / divider}${unit})`);
              this._renderer.setStyle(nativeElement, 'top', `calc(50% - ${clientHeight + gutter}${unit})`);
              this._renderer.setStyle(nativeElement, 'z-index', 2);
              break;
            case 2:
              this._renderer.setStyle(nativeElement, 'right', `calc(50% - ${(clientWidth * 1.5) + (gutter * 2)}${unit})`);
              this._renderer.setStyle(nativeElement, 'top', `calc(50% - ${clientHeight + gutter}${unit})`);
              this._renderer.setStyle(nativeElement, 'z-index', 2);
              break;
            case 3:

              this._renderer.setStyle(nativeElement, 'left', `calc(50% - ${(clientWidth * 1.5) + (gutter * 2)}${unit})`);
              this._renderer.setStyle(nativeElement, 'bottom', `calc(50% - ${clientHeight + gutter}${unit})`);
              this._renderer.setStyle(nativeElement, 'z-index', 3);
              break;
            case 4:
              this._renderer.setStyle(nativeElement, 'left', `calc(50% - ${clientWidth / divider}${unit})`);
              this._renderer.setStyle(nativeElement, 'bottom', `calc(50% - ${clientHeight + gutter}${unit})`);
              this._renderer.setStyle(nativeElement, 'z-index', 3);
              break;
            case 5:
              this._renderer.setStyle(nativeElement, 'right', `calc(50% - ${(clientWidth * 1.5) + (gutter * 2)}${unit})`);
              this._renderer.setStyle(nativeElement, 'bottom', `calc(50% - ${clientHeight + gutter}${unit})`);
              this._renderer.setStyle(nativeElement, 'z-index', 3);
              break;
          }
          break;
        } else {
          switch (tileIndex) {
            case 0:
              this._renderer.setStyle(nativeElement, 'left', `calc(50% - ${clientWidth + gutter}${unit})`);
              this._renderer.setStyle(nativeElement, 'top', `calc(50% - ${clientHeight + gutter}${unit})`);
              this._renderer.setStyle(nativeElement, 'z-index', 2);
              break;
            case 1:
              this._renderer.setStyle(nativeElement, 'right', `calc(50% - ${clientWidth + gutter}${unit})`);
              this._renderer.setStyle(nativeElement, 'top', `calc(50% - ${clientHeight + gutter}${unit})`);
              this._renderer.setStyle(nativeElement, 'z-index', 2);
              break;
            case 2:
              this._renderer.setStyle(nativeElement, 'left', `calc(50% - ${(clientWidth * 1.5) + (gutter * 2)}${unit})`);
              this._renderer.setStyle(nativeElement, 'top', `calc(50% - ${(clientHeight / divider)}${unit})`);
              this._renderer.setStyle(nativeElement, 'z-index', 3);
              break;
            case 3:

              this._renderer.setStyle(nativeElement, 'left', `calc(50% - ${clientWidth / divider}${unit})`);
              this._renderer.setStyle(nativeElement, 'top', `calc(50% - ${clientHeight / divider}${unit})`);
              this._renderer.setStyle(nativeElement, 'z-index', 3);
              break;
            case 4:
              this._renderer.setStyle(nativeElement, 'left', `calc(50% - ${clientWidth + gutter}${unit})`);
              this._renderer.setStyle(nativeElement, 'bottom', `calc(50% - ${clientHeight + gutter}${unit})`);
              this._renderer.setStyle(nativeElement, 'z-index', 4);
              break;
            case 5:
              this._renderer.setStyle(nativeElement, 'right', `calc(50% - ${clientWidth + gutter}${unit})`);
              this._renderer.setStyle(nativeElement, 'bottom', `calc(50% - ${clientHeight + gutter}${unit})`);
              this._renderer.setStyle(nativeElement, 'z-index', 4);
              break;
          }
          break;
        }
      }
      case 7: {
        if (isGrid) {
          switch (tileIndex) {
            case 0:
              this._renderer.setStyle(nativeElement, 'left', `calc(50% - ${(clientWidth * 1.5) + (gutter * 2)}${unit})`);
              this._renderer.setStyle(nativeElement, 'top', `calc(50% - ${clientHeight + (gutter * 100)}${unit})`);
              this._renderer.setStyle(nativeElement, 'z-index', 2);
              break;
            case 1:
              this._renderer.setStyle(nativeElement, 'left', `calc(50% - ${clientWidth / divider}${unit})`);
              this._renderer.setStyle(nativeElement, 'top', `calc(50% - ${clientHeight + (gutter * 100)}${unit})`);
              this._renderer.setStyle(nativeElement, 'z-index', 2);
              break;
            case 2:
              this._renderer.setStyle(nativeElement, 'right', `calc(50% - ${(clientWidth * 1.5) + (gutter * 2)}${unit})`);
              this._renderer.setStyle(nativeElement, 'top', `calc(50% - ${clientHeight + (gutter * 100)}${unit})`);
              this._renderer.setStyle(nativeElement, 'z-index', 2);
              break;
            case 3:
              this._renderer.setStyle(nativeElement, 'left', `calc(50% - ${(clientWidth * 1.5) + (gutter * 2)}${unit})`);
              this._renderer.setStyle(nativeElement, 'top', `calc(50% - ${clientHeight / divider}${unit})`);
              this._renderer.setStyle(nativeElement, 'z-index', 3);
              break;
            case 4:
              this._renderer.setStyle(nativeElement, 'left', `calc(50% -  ${clientWidth / divider}${unit})`);
              this._renderer.setStyle(nativeElement, 'top', `calc(50% - ${clientHeight / divider}${unit})`);
              this._renderer.setStyle(nativeElement, 'z-index', 3);
              break;
            case 5:
              this._renderer.setStyle(nativeElement, 'right', `calc(50% - ${(clientWidth * 1.5) + (gutter * 2)}${unit})`);
              this._renderer.setStyle(nativeElement, 'top', `calc(50% - ${clientHeight / divider}${unit})`);
              this._renderer.setStyle(nativeElement, 'z-index', 3);
              break;
            case 6:
              this._renderer.setStyle(nativeElement, 'left', `calc(50% -  ${clientWidth / divider}${unit})`);
              this._renderer.setStyle(nativeElement, 'bottom', `calc(50% - ${clientHeight + (gutter * 100)}${unit})`);
              this._renderer.setStyle(nativeElement, 'z-index', 4);
              break;
          }
          break;
        } else {
          switch (tileIndex) {
            case 0:
              this._renderer.setStyle(nativeElement, 'left', `calc(50% - ${clientWidth + gutter}${unit})`);
              this._renderer.setStyle(nativeElement, 'top', `calc(50% - ${clientHeight + gutter}${unit})`);
              this._renderer.setStyle(nativeElement, 'z-index', 2);
              break;
            case 1:
              this._renderer.setStyle(nativeElement, 'right', `calc(50% - ${clientWidth + gutter}${unit})`);
              this._renderer.setStyle(nativeElement, 'top', `calc(50% - ${clientHeight + gutter}${unit})`);
              this._renderer.setStyle(nativeElement, 'z-index', 2);
              break;
            case 2:
              this._renderer.setStyle(nativeElement, 'left', `calc(50% - ${(clientWidth * 1.5) + (gutter * 2)}${unit})`);
              this._renderer.setStyle(nativeElement, 'top', `calc(50% - ${(clientHeight / divider)}${unit})`);
              this._renderer.setStyle(nativeElement, 'z-index', 3);
              break;
            case 3:

              this._renderer.setStyle(nativeElement, 'left', `calc(50% - ${clientWidth / divider}${unit})`);
              this._renderer.setStyle(nativeElement, 'top', `calc(50% - ${clientHeight / divider}${unit})`);
              this._renderer.setStyle(nativeElement, 'z-index', 3);
              break;
            case 4:
              this._renderer.setStyle(nativeElement, 'right', `calc(50% - ${(clientWidth * 1.5) + (gutter * 2)}${unit})`);
              this._renderer.setStyle(nativeElement, 'top', `calc(50% - ${clientHeight / divider}${unit})`);
              this._renderer.setStyle(nativeElement, 'z-index', 3);
              break;
            case 5:
              this._renderer.setStyle(nativeElement, 'left', `calc(50% - ${clientWidth + gutter}${unit})`);
              this._renderer.setStyle(nativeElement, 'bottom', `calc(50% - ${clientHeight + gutter}${unit})`);
              this._renderer.setStyle(nativeElement, 'z-index', 4);
              break;
            case 6:
              this._renderer.setStyle(nativeElement, 'right', `calc(50% - ${clientWidth + gutter}${unit})`);
              this._renderer.setStyle(nativeElement, 'bottom', `calc(50% - ${clientHeight + gutter}${unit})`);
              this._renderer.setStyle(nativeElement, 'z-index', 4);
              break;
          }
          break;
        }

      }
      case 8: {
        if (isGrid) {
          switch (tileIndex) {
            case 0:
              this._renderer.setStyle(nativeElement, 'left', `calc(50% - ${(clientWidth * 1.5) + (gutter * 2)}${unit})`);
              this._renderer.setStyle(nativeElement, 'top', `calc(50% - ${clientHeight + (gutter * 100)}${unit})`);
              this._renderer.setStyle(nativeElement, 'z-index', 2);
              break;
            case 1:
              this._renderer.setStyle(nativeElement, 'left', `calc(50% - ${clientWidth / divider}${unit})`);
              this._renderer.setStyle(nativeElement, 'top', `calc(50% - ${clientHeight + (gutter * 100)}${unit})`);
              this._renderer.setStyle(nativeElement, 'z-index', 2);
              break;
            case 2:
              this._renderer.setStyle(nativeElement, 'right', `calc(50% - ${(clientWidth * 1.5) + (gutter * 2)}${unit})`);
              this._renderer.setStyle(nativeElement, 'top', `calc(50% - ${clientHeight + (gutter * 100)}${unit})`);
              this._renderer.setStyle(nativeElement, 'z-index', 2);
              break;
            case 3:
              this._renderer.setStyle(nativeElement, 'left', `calc(50% - ${(clientWidth * 1.5) + (gutter * 2)}${unit})`);
              this._renderer.setStyle(nativeElement, 'top', `calc(50% - ${clientHeight / divider}${unit})`);
              this._renderer.setStyle(nativeElement, 'z-index', 3);
              break;
            case 4:
              this._renderer.setStyle(nativeElement, 'left', `calc(50% -  ${clientWidth / divider}${unit})`);
              this._renderer.setStyle(nativeElement, 'top', `calc(50% - ${clientHeight / divider}${unit})`);
              this._renderer.setStyle(nativeElement, 'z-index', 3);
              break;
            case 5:
              this._renderer.setStyle(nativeElement, 'right', `calc(50% - ${(clientWidth * 1.5) + (gutter * 2)}${unit})`);
              this._renderer.setStyle(nativeElement, 'top', `calc(50% - ${clientHeight / divider}${unit})`);
              this._renderer.setStyle(nativeElement, 'z-index', 3);
              break;
            case 6:
              this._renderer.setStyle(nativeElement, 'left', `calc(50% - ${clientWidth + gutter}${unit})`);
              this._renderer.setStyle(nativeElement, 'bottom', `calc(50% - ${clientHeight + (gutter * 100)}${unit})`);
              this._renderer.setStyle(nativeElement, 'z-index', 4);
              break;
            case 7:
              this._renderer.setStyle(nativeElement, 'right', `calc(50% - ${clientWidth + gutter}${unit})`);
              this._renderer.setStyle(nativeElement, 'bottom', `calc(50% - ${clientHeight + (gutter * 100)}${unit})`);
              this._renderer.setStyle(nativeElement, 'z-index', 4);
              break;
          }
          break;
        } else {
          switch (tileIndex) {
            case 0:
              this._renderer.setStyle(nativeElement, 'left', `calc(50% - ${clientWidth / divider}${unit})`);
              this._renderer.setStyle(nativeElement, 'top', `calc(50% - ${(clientHeight * 1.5) + (gutter * 2)}${unit})`);
              this._renderer.setStyle(nativeElement, 'z-index', 2);
              break;
            case 1:
              this._renderer.setStyle(nativeElement, 'left', `calc(50% - ${clientWidth + gutter}${unit})`);
              this._renderer.setStyle(nativeElement, 'top', `calc(50% - ${clientHeight + gutter}${unit})`);
              this._renderer.setStyle(nativeElement, 'z-index', 3);
              break;
            case 2:
              this._renderer.setStyle(nativeElement, 'right', `calc(50% - ${clientWidth + gutter}${unit})`);
              this._renderer.setStyle(nativeElement, 'top', `calc(50% - ${clientHeight + gutter}${unit})`);
              this._renderer.setStyle(nativeElement, 'z-index', 3);
              break;
            case 3:
              this._renderer.setStyle(nativeElement, 'left', `calc(50% - ${(clientWidth * 1.5) + (gutter * 2)}${unit})`);
              this._renderer.setStyle(nativeElement, 'top', `calc(50% - ${(clientHeight / divider)}${unit})`);
              this._renderer.setStyle(nativeElement, 'z-index', 4);
              break;
            case 4:
              this._renderer.setStyle(nativeElement, 'left', `calc(50% - ${clientWidth / divider}${unit})`);
              this._renderer.setStyle(nativeElement, 'top', `calc(50% - ${clientHeight / divider}${unit})`);
              this._renderer.setStyle(nativeElement, 'z-index', 4);
              break;
            case 5:
              this._renderer.setStyle(nativeElement, 'right', `calc(50% - ${(clientWidth * 1.5) + (gutter * 2)}${unit})`);
              this._renderer.setStyle(nativeElement, 'top', `calc(50% - ${clientHeight / divider}${unit})`);
              this._renderer.setStyle(nativeElement, 'z-index', 4);
              break;
            case 6:
              this._renderer.setStyle(nativeElement, 'left', `calc(50% - ${clientWidth + gutter}${unit})`);
              this._renderer.setStyle(nativeElement, 'bottom', `calc(50% - ${clientHeight + gutter}${unit})`);
              this._renderer.setStyle(nativeElement, 'z-index', 5);
              break;
            case 7:
              this._renderer.setStyle(nativeElement, 'right', `calc(50% - ${clientWidth + gutter}${unit})`);
              this._renderer.setStyle(nativeElement, 'bottom', `calc(50% - ${clientHeight + gutter}${unit})`);
              this._renderer.setStyle(nativeElement, 'z-index', 5);
              break;

          }
          break;
        }

      }
      case 9: {
        if (isGrid) {
          switch (tileIndex) {
            case 0:
              this._renderer.setStyle(nativeElement, 'left', `calc(50% - ${(clientWidth * 1.5) + (gutter * 2)}${unit})`);
              this._renderer.setStyle(nativeElement, 'top', `calc(50% - ${clientHeight + (gutter * 100)}${unit})`);
              this._renderer.setStyle(nativeElement, 'z-index', 2);
              break;
            case 1:
              this._renderer.setStyle(nativeElement, 'left', `calc(50% - ${clientWidth / divider}${unit})`);
              this._renderer.setStyle(nativeElement, 'top', `calc(50% - ${clientHeight + (gutter * 100)}${unit})`);
              this._renderer.setStyle(nativeElement, 'z-index', 2);
              break;
            case 2:
              this._renderer.setStyle(nativeElement, 'right', `calc(50% - ${(clientWidth * 1.5) + (gutter * 2)}${unit})`);
              this._renderer.setStyle(nativeElement, 'top', `calc(50% - ${clientHeight + (gutter * 100)}${unit})`);
              this._renderer.setStyle(nativeElement, 'z-index', 2);
              break;
            case 3:
              this._renderer.setStyle(nativeElement, 'left', `calc(50% - ${(clientWidth * 1.5) + (gutter * 2)}${unit})`);
              this._renderer.setStyle(nativeElement, 'top', `calc(50% - ${clientHeight / divider}${unit})`);
              this._renderer.setStyle(nativeElement, 'z-index', 3);
              break;
            case 4:
              this._renderer.setStyle(nativeElement, 'left', `calc(50% -  ${clientWidth / divider}${unit})`);
              this._renderer.setStyle(nativeElement, 'top', `calc(50% - ${clientHeight / divider}${unit})`);
              this._renderer.setStyle(nativeElement, 'z-index', 3);
              break;
            case 5:
              this._renderer.setStyle(nativeElement, 'right', `calc(50% - ${(clientWidth * 1.5) + (gutter * 2)}${unit})`);
              this._renderer.setStyle(nativeElement, 'top', `calc(50% - ${clientHeight / divider}${unit})`);
              this._renderer.setStyle(nativeElement, 'z-index', 3);
              break;
            case 6:
              this._renderer.setStyle(nativeElement, 'left', `calc(50% - ${(clientWidth * 1.5) + (gutter * 2)}${unit})`);
              this._renderer.setStyle(nativeElement, 'bottom', `calc(50% - ${clientHeight + (gutter * 100)}${unit})`);
              this._renderer.setStyle(nativeElement, 'z-index', 4);
              break;
            case 7:
              this._renderer.setStyle(nativeElement, 'left', `calc(50% -  ${clientWidth / divider}${unit})`);
              this._renderer.setStyle(nativeElement, 'bottom', `calc(50% - ${clientHeight + (gutter * 100)}${unit})`);
              this._renderer.setStyle(nativeElement, 'z-index', 4);
              break;
            case 8:
              this._renderer.setStyle(nativeElement, 'right', `calc(50% - ${(clientWidth * 1.5) + (gutter * 2)}${unit})`);
              this._renderer.setStyle(nativeElement, 'bottom', `calc(50% - ${clientHeight + (gutter * 100)}${unit})`);
              this._renderer.setStyle(nativeElement, 'z-index', 4);
              break;
          }
          break;
        } else {
          switch (tileIndex) {
            case 0:
              this._renderer.setStyle(nativeElement, 'left', `calc(50% - ${clientWidth / divider}${unit})`);
              this._renderer.setStyle(nativeElement, 'top', `calc(50% - ${(clientHeight * 1.5) + (gutter * 2)}${unit})`);
              this._renderer.setStyle(nativeElement, 'z-index', 2);
              break;
            case 1:
              this._renderer.setStyle(nativeElement, 'left', `calc(50% - ${clientWidth + gutter}${unit})`);
              this._renderer.setStyle(nativeElement, 'top', `calc(50% - ${clientHeight + gutter}${unit})`);
              this._renderer.setStyle(nativeElement, 'z-index', 3);
              break;
            case 2:
              this._renderer.setStyle(nativeElement, 'right', `calc(50% - ${clientWidth + gutter}${unit})`);
              this._renderer.setStyle(nativeElement, 'top', `calc(50% - ${clientHeight + gutter}${unit})`);
              this._renderer.setStyle(nativeElement, 'z-index', 3);
              break;
            case 3:
              this._renderer.setStyle(nativeElement, 'left', `calc(50% - ${(clientWidth * 1.5) + (gutter * 2)}${unit})`);
              this._renderer.setStyle(nativeElement, 'top', `calc(50% - ${(clientHeight / divider)}${unit})`);
              this._renderer.setStyle(nativeElement, 'z-index', 4);
              break;
            case 4:
              this._renderer.setStyle(nativeElement, 'left', `calc(50% - ${clientWidth / divider}${unit})`);
              this._renderer.setStyle(nativeElement, 'top', `calc(50% - ${clientHeight / divider}${unit})`);
              this._renderer.setStyle(nativeElement, 'z-index', 4);
              break;
            case 5:
              this._renderer.setStyle(nativeElement, 'right', `calc(50% - ${(clientWidth * 1.5) + (gutter * 2)}${unit})`);
              this._renderer.setStyle(nativeElement, 'top', `calc(50% - ${clientHeight / divider}${unit})`);
              this._renderer.setStyle(nativeElement, 'z-index', 4);
              break;
            case 6:
              this._renderer.setStyle(nativeElement, 'left', `calc(50% - ${clientWidth + gutter}${unit})`);
              this._renderer.setStyle(nativeElement, 'bottom', `calc(50% - ${clientHeight + gutter}${unit})`);
              this._renderer.setStyle(nativeElement, 'z-index', 5);
              break;
            case 7:
              this._renderer.setStyle(nativeElement, 'right', `calc(50% - ${clientWidth + gutter}${unit})`);
              this._renderer.setStyle(nativeElement, 'bottom', `calc(50% - ${clientHeight + gutter}${unit})`);
              this._renderer.setStyle(nativeElement, 'z-index', 5);
              break;
            case 8:
              this._renderer.setStyle(nativeElement, 'right', `calc(50% - ${clientWidth / divider}${unit})`);
              this._renderer.setStyle(nativeElement, 'bottom', `calc(50% - ${(clientHeight * 1.5) + (gutter * 2)}${unit})`);
              this._renderer.setStyle(nativeElement, 'z-index', 6);
              break;
          }
          break;
        }
      }
    }
  }

}
